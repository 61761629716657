import React from 'react';
import style from './style.module.less';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';




const MenuPartners = ({ selectedMenuItem, match }) => {

    const pathname = match.url;

    return (
        <Menu selectedKeys={[selectedMenuItem]} mode="horizontal">
            <Menu.Item key="information" className={selectedMenuItem === 'information' ? style.menuItemSelected : style.menuItem}>
                <Link to={`${pathname}/information`} className={selectedMenuItem === 'information' ? style.linkSelected : style.linkItem}>
                    Partner information
                </Link>
            </Menu.Item>
            <Menu.Item key="package" className={selectedMenuItem === 'package' ? style.menuItemSelected : style.menuItem}>
                <Link to={`${pathname}/package`} className={selectedMenuItem === 'package' ? style.linkSelected : style.linkItem}>
                    API package
                </Link>
            </Menu.Item>
            <Menu.Item key="apiKey" className={selectedMenuItem === 'apiKey' ? style.menuItemSelected : style.menuItem}>
                <Link to={`${pathname}/apikey`} className={selectedMenuItem === 'apikey' ? style.linkSelected : style.linkItem}>
                    API Key
                </Link>
            </Menu.Item>
            <Menu.Item key="billing" className={selectedMenuItem === 'billing' ? style.menuItemSelected : style.menuItem}>
                <Link to={`${pathname}/billing`} className={selectedMenuItem === 'billing' ? style.linkSelected : style.linkItem}>
                    Billing
                </Link>

            </Menu.Item>
        </Menu>
    );
};

export default MenuPartners;
