import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v2/api/conversations`;

export const { types, actions, rootReducer } = createResource({
  name: 'conversation',
  url,

  actions: {
    fetch: {
      method: 'POST',
      transformResponse: res => {
        return {
          res,
          body: res.body
        };
      },
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return {
            ...state,
            isFetching: true
          };
        }
        if (action.context.skip !== 0) {
          return {
            ...state,
            isFetching: false,
            items: [...state.items, ...action.body]
          };
        } else {
          return {
            ...state,
            items: [...action.body],
            isFetching: false
          };
        }
      }
    },
    updateLastMessage: {
      url: `${API_URL}v2/api/conversations/:id`,
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return state;
        }
        const oldConverastions = state.items.filter(item => item.id !== action.body.id);
        const updatedConversations = [action.body, ...oldConverastions];
        return { ...state, items: updatedConversations };
      }
    },
    updateHiddenConversation: {
      method: 'PATCH',
      url: `${API_URL}v2/api/conversations/:id`,
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return state;
        }
        return { ...state };
      }
    },
    updateConversationsbyIds: {
      method: 'PATCH',
      url: `${API_URL}v2/api/conversationsread/:id`,
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return state;
        }
        const originalArray = state.items;
        const valuesToUpdate = action.body.updatedConversations;
        const updatedConversation = originalArray.map(item => {
          const matchingValue = valuesToUpdate.find(value => value.id === item.id);
          if (matchingValue) {
            return { ...item, isRead: matchingValue.isRead, unreadCount: matchingValue.unreadCount };
          }

          return item;
        });

        return { items: updatedConversation, unreadTotalCount: action.body.unreadTotalCount };
      }
    },
    count: {
      method: 'GET',
      url: `${API_URL}v2/api/conversations/count/:id`,
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return state;
        }
        return { ...state, count: action.body.count };
      }
    },

    getUnreadTotalCount: {
      method: 'GET',
      url: `${API_URL}v2/api/conversations/unreadTotalCount`,
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return state;
        }
        return { ...state, unreadTotalCount: action.body.unreadTotalCount };
      }
    },

    read: {
      method: 'POST',
      url: `${API_URL}v2/api/conversations/read/:id`,
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return state;
        }

        const updatedItems = state.items.map(c => (c.id === action.body.id ? { ...c, isRead: action.body.isRead } : c));
        return { ...state, items: updatedItems, unreadTotalCount: action.body.unreadTotalCount };
      }
    },
    updateUnreadCount: {
      method: 'PATCH',
      url: `${API_URL}v2/api/conversations/:id`,
      reduce: (state, action) => {
        if (action.status !== 'resolved' || !action.body) {
          return state;
        }

        const updatedItems = state.items.map(c => (c.id === action.context.id ? { ...c, unreadCount: action.body.unreadCount } : c));
        return { ...state, items: updatedItems };
      }
    }
  }
});
