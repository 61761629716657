import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v2/api/reviews/image`;

export const { types, actions, rootReducer } = createResource({
  name: 'reviewForExport',
  url,
  actions: {
    save: {
      method: 'POST',
     },
     saveAll: {
       url: `${API_URL}v2/api/reviews/export/pdf`, 
       method: "GET"
     }
  }
});