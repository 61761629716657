import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v2/api/pages`;

export const { types, actions, rootReducer } = createResource({
  name: 'pages',
  url,
  actions: {
    initiateLoading: {
      isPure: true,
      reduce: (state) => {
        return { ...state, isFetching: true };
      }
    },
    // getMessagesPages() method
    getMessages: {
      method: 'GET',
      url: `${API_URL}v2/api/pages/:id`,
      transformResponse: res => {
        return {
          res,
          body: res.body
        };
      },
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return state;
        }
        return { ...state, items: action.body, isFetching: false };
      }
    },
    // updateMessagesPages() method
    updateMessages: {
      method: 'PATCH',
      url: `${API_URL}v2/api/pages/update/:id`,
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return state;
        }
        const pages = [...state.items];
        return { items: pages };
      }
    }
  }
});
