export const COUNTRIES = [
  'United Arab Emirates',
  'Saudi Arabia',
  'Bahrain',
  'Oman',
  'Kuwait',
  'Qatar',
  'Jordan',
  'Egypt',
  'Lebanon',
  'Syria',
  'Iraq',
  'Libya',
  'Algeria',
  'Tunisia',
  'Morocco',
  'Yemen',
  'Palestine'
];

export const LINK_FOR_CANADA = 'https://billing.stripe.com/p/login/bIYaEI3ML24EaDS4gg';
export const LINK_FOR_MIDDLE_EAST_COUNTRY = 'https://billing.stripe.com/p/login/cN203L7AfcYO9IkfYY';
export const LINK_FOR_OTHER_COUNTRY = 'https://billing.stripe.com/p/login/00g9DGbly5DS5X2288';
