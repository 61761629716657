import React from 'react';
import classNames from 'classnames';
import style from './style.module.less';

const Buttons = ({ onBack, onNextToCompanies, nextButtonTitle }) => {
    return (
        <div className={style.goBackButtonsWrapper}>
            <span onClick={onBack} className={style.goBackButton}>
                Back
            </span>
            <span onClick={onNextToCompanies} className={classNames(style.goBackButton, style.onBackToCompanies)}>
                {nextButtonTitle}
            </span>
        </div>
    );
};

export default Buttons;