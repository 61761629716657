export default (state = {}, action) => {
  switch (action.type) {
    case '@@resource/COMPANY/FETCH':
      if (action.status === 'resolved') {
        return action.paginationCompanies;
      }
      return state;
    default:
      return state;
  }
};
