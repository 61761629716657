import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

export const { type, actions, rootReducer } = createResource({
  name: 'messageTranslation',

  actions: {
    fetch: {
      method: 'GET',
      url: `${API_URL}v2/api/messageTranslation/get`,
    },

    upsert: {
      method: 'POST',
      url: `${API_URL}v2/api/messageTranslation/upsert`
    },
  }
});
