import { defaultHeaders, defaultGlobals } from 'redux-rest-resource';
import { refreshToken } from './changeUserTokens';
import { updateQueryUrl } from 'helpers/helpers';
// import BPromise from 'bluebird';

// BPromise.config({cancellation: true});

// const abortableFetch =(request, opts)=>{
//   const controller = new AbortController();
//   const signal = controller.signal;
//   return (new BPromise((resolve, reject, onCancel) => {
//       onCancel(() => {
//         controller.abort();
//       });

//       resolve(fetch(request, { ...opts, signal }));
//     }));
//   }

const setResourceToken = token => {
  Object.assign(defaultHeaders, {
    Authorization: `Bearer ${token}`
  });
}; //function wich add  to defaultHeaders Authorization bearer

window.setResourceToken = setResourceToken;
setResourceToken(JSON.parse(localStorage.getItem('user') || '{}').access_token);
//run setResourceToken with access_token

Object.assign(defaultGlobals, {
  fetch: (url, ...restArgs) => {
    const updatedUrl = updateQueryUrl(url);
    const newArgs = [updatedUrl, ...restArgs];

    return fetch.apply(this, newArgs).then(errorResponse => {
      if (errorResponse.status === 400) {
        const clonedErrorResponse = errorResponse.clone();
        return clonedErrorResponse.text()?.then(response => {
          if (response === 'expired') {
            return refreshToken().then(() => fetch.apply(this, newArgs));
          }
          return errorResponse;
        });
      }
      if (errorResponse.status === 403) {
        return refreshToken().then(() => fetch.apply(this, newArgs));
      }
      if (errorResponse?.status && errorResponse.status > 299) {
        console.error(errorResponse);
      }
      return errorResponse;
    });
  }
});
// Object.assign(defaultGlobals, {
//   fetch: (url, ...restArgs) => {
//     const updatedUrl = updateQueryUrl(url);
//     const newArgs = [updatedUrl, ...restArgs];

//     return abortableFetch.apply(this, newArgs).then(errorResponse => {
//       if (errorResponse.status === 400) {
//         const clonedErrorResponse = errorResponse.clone();
//         return clonedErrorResponse.text()?.then(response => {
//           if (response === 'expired') {
//             return refreshToken().then(() => abortableFetch.apply(this, newArgs));
//           }
//           return errorResponse;
//         });
//       }
//       if (errorResponse.status === 403) {
//         return refreshToken().then(() => abortableFetch.apply(this, newArgs));
//       }
//       if (errorResponse?.status && errorResponse.status > 299) {
//         console.error(errorResponse);
//       }
//       return errorResponse;
//     });
//   },
//   Promise: BPromise
// });

export default setResourceToken;
