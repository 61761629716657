import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { API_URL, CLIENT_ID, CLIENT_SECRET } from '../../config';
import { saveUserTokens } from '../resources/changeUserTokens';
import axios from 'axios';
import { SIGNIN_USER, SIGNOUT_USER, SIGNUP_USER } from 'constants/ActionTypes';
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpSuccess
} from '../../appRedux/actions/Auth';
import setResourceToken from '../resources/config';
import { clearLocalStoragFromUserRoles } from 'helpers/helpers';


const createUserWithEmailPasswordRequest = (email, password) =>
  axios({
    method: 'post',
    url: `${API_URL}v2/oauth2/token`,
    data: {
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      grant_type: 'password',
      email: email,
      password: password
    }
  })
    .createUserWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithEmailPasswordRequest = async (email, password) =>
  await axios({
    method: 'post',
    url: `${API_URL}v2/oauth2/token`,
    data: {
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      grant_type: 'password',
      email: email,
      password: password
    }
  })
    .then(authUser => authUser.data)
    .catch(error => error);


function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
    if (signInUser.error_description) {
      yield put(showAuthMessage(signInUser.error_description));
    } else {
      const signInUserData = {...signInUser, email: email};
      yield call(saveUserTokens, { data: signInUserData });
      yield put(userSignInSuccess(signInUser.access_token));
      yield call(setResourceToken, signInUser.access_token);
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}
function* createUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
 
  try {
    const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      const access_keys = {
        access_token: signInUser.access_token,
        refresh_token: signInUser.refresh_token,
        email: email,
      };
      localStorage.setItem('user', JSON.stringify(access_keys));
      yield put(userSignUpSuccess(signInUser.access_token));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  const signOutUser = undefined;

  try {
    if (signOutUser === undefined) {
      localStorage.removeItem('user');
      clearLocalStoragFromUserRoles();
      // yield put(clearAllReviews());
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}
export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([fork(signInUser), fork(signOutUser)]);
}
