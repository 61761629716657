import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v2/api/user/filters`;

// TODO: legacy filter logic, to be removed/reworked 26-07-2024 (VS)
export const { types, actions, rootReducer } = createResource({
  name: 'userFilter',
  url,
  actions: {
    fetch: {
      method: 'GET',
      transformResponse: res => {
        return {
          res,
          body: res.body.filters
        };
      }
    },
    save: {
      method: 'POST',
      reduce: (state, action) => {
        const updatedFilters = { ...action.context };
        if (action.status !== 'resolved') {
          return state;
        }
        return { ...state, items: [updatedFilters] };
      }
    }
  }
});
