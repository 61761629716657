import React from 'react';
import { Form, Popconfirm, Input } from 'antd';
import style from './style.module.less';
import classnames from 'classnames';
// import { CopyToClipboard } from 'react-copy-to-clipboard';

const Locations = ({ onBack, onNext, form, updateCompanyLocationsLimit, editedCompany, visible, currentuserRole }) => {
  const { getFieldDecorator, isFieldsTouched } = form;
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 }
    }
  };
  const onUpdateCompanyInfo = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err && form.isFieldsTouched()) {
        const updateCompany = {
          id: editedCompany.id,
          ...values
        };
        updateCompanyLocationsLimit(updateCompany);
      } else {
        onNext();
      }
    });
  };

  return (
    <>
      <Form {...formItemLayout} onSubmit={onUpdateCompanyInfo} labelAlign="left" className={classnames(visible ? style.companyForm : style.hideForm)}>
        <Form.Item label="Number of the locations allowed" className={style.formItem}>
          {getFieldDecorator('locationsLimit', {
            initialValue: editedCompany ? editedCompany.locationsLimit : null
          })(<Input type="number" />)}
        </Form.Item>

        <Form.Item>
          <div className={style.buttonsWrapper}>
            {isFieldsTouched() ? (
              <Popconfirm
                title={
                  editedCompany
                    ? "Changes aren't saved. Are you sure you want to go back?"
                    : "Company settings aren't saved. Are your sure you want to go back?"
                }
                onConfirm={onBack}
                okText="Yes"
                cancelText="No"
              >
                <span className={style.goBackButton}>Back</span>
              </Popconfirm>
            ) : (
              <span className={style.goBackButton} onClick={onBack}>
                Back
              </span>
            )}
            <button className={style.nextButton} htmltype="submit">
              Next
            </button>
          </div>
        </Form.Item>
      </Form>
      {/* <Buttons onBack={onBack} onNextToCompanies={onNext} nextButtonTitle='Next' /> */}
    </>
  );
};

const WrappedLocations = Form.create({ name: 'compny_locations' })(Locations);

export default WrappedLocations;
