import React from 'react';
import style from './style.module.less';
import { Link } from 'react-router-dom';
import BillingExport from '../BillingExport';


const PartnerBilling = ({ editedPartnerId }) => {

    const currentPathname = window.location.pathname.split('/');
    currentPathname.splice(-1, 1);
    const pathname = currentPathname.join('/');

    return (
        <>
            <BillingExport apiType='Free API' freeApi={true} partnerId={editedPartnerId} />
            <BillingExport apiType='Paid API' partnerId={editedPartnerId} />
            <div className={style.buttonsWrapper}>
                <Link to={`${pathname}/apikey`} className={style.buttonStyle}>
                    Back
                </Link>

                <Link to={`/admin_panel/partners`} className={style.buttonStyle}>
                    Back to partners
                </Link>
            </div>
        </>

    );
};

export default PartnerBilling;