import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import SignIn from '../SignIn';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import ConfirmPassword from '../ConfirmPassword/ConfirmPassword';
import Invitation from '../signUp/Invitation';
import AppLocale from 'lngProvider';
import MainApp from './MainApp';
import ReviewForExport from '../../routes/Reviews/ReviewForExport';
import { setInitUrl } from 'appRedux/actions/Auth';
import { onLayoutTypeChange, onNavStyleChange, setThemeType } from 'appRedux/actions/Setting';
import moment from 'moment';
import 'moment/locale/en-gb';
import newLocale from 'antd/es/locale/en_GB';
import MetaTags from 'react-meta-tags';

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK
} from '../../constants/ThemeSetting';

const PDF_EXPORT_MODE = () => !!window.REDUX_DATA;

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/signin',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);
class App extends Component {
  setLayoutType = layoutType => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  setNavStyle = navStyle => {
    if (
      navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER
    ) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  componentDidMount() {
    if (!PDF_EXPORT_MODE()) {
      moment.locale('en-gb', {
        week: {
          dow: 7 //default start if week value
        }
      });
      if (this.props.initURL === '') {
        this.props.setInitUrl(this.props.history.location.pathname);
      }
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      document.documentElement.style.setProperty('--bannerHeight', '0px');
    }
  }
  componentDidUpdate(prevprops) {
    const { currentUserRole, startOfWeek } = this.props;
    if (prevprops.startOfWeek !== startOfWeek) {
      moment.locale('en-gb', {
        week: {
          dow: startOfWeek //change start of week value for date pickers across the app
        }
      });
    }
    if (prevprops.currentUserRole !== currentUserRole) {
      if (currentUserRole === 'Partner') {
        return <Redirect to={'/admin_panel/companies'} />;
      } else {
        return <Redirect to={'/reviews'} />;
      }
    }
  }

  render() {
    const { match, location, themeType, layoutType, navStyle, locale, authUser, initURL, currentUserRole } = this.props;
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add('dark-theme');
    }

    if (
      authUser === null &&
      location.pathname !== '/signin' &&
      location.pathname !== '/forgotPassword' &&
      !location.pathname.includes('confirmPassword') &&
      !location.pathname.includes('invitation') &&
      !location.pathname.includes('review/')
    ) {
      if (
        !location.pathname.includes('/invitation') ||
        !location.pathname.includes('/forgotPassword') ||
        !location.pathname.includes('/confirmPassword') ||
        !location.pathname.includes('review/')
      ) {
        return <Redirect to={{ pathname: '/signin', state: { from: location } }} />;
      }
    }

    if (location.pathname === '/') {
      if (
        initURL === '' ||
        initURL === '/' ||
        initURL === '/signin' ||
        initURL === '/forgotPassword' ||
        initURL.includes('/invitation/') ||
        initURL.includes('/confirmPassword/') ||
        initURL.includes('/review/')
      ) {
        if (currentUserRole) {
          if (currentUserRole === 'Partner') {
            return <Redirect to={'/admin_panel/companies'} />;
          } else {
            return <Redirect to={'/reviews'} />;
          }
        }
      } else {
        return <Redirect to={initURL} />;
      }
    }

    if (authUser && location.state && location.state.from) {
      return <Redirect to={location.state.from} />;
    }

    this.setLayoutType(layoutType);

    this.setNavStyle(navStyle);

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <>
        <MetaTags>
          <title>{'Localyser ' + (process.env.REACT_APP_NODE_ENV !== 'production' ? ` ${process.env.REACT_APP_NODE_ENV.toUpperCase()}` : '')}</title>
          <meta name="description" content="Increase your sales with Online Reviews" />
          <meta property="og:title" content={'Localyser ' + (process.env.REACT_APP_NODE_ENV !== 'production' ? ` ${process.env.REACT_APP_NODE_ENV.toUpperCase()}` : '')} />
          <meta property="og:image" content={`${process.env.PUBLIC_URL}/og_localyser_logo.png`} />
          <meta property="og:url" content="https://www.localyser.com/" />
        </MetaTags>

        <ConfigProvider locale={newLocale}>
          <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
            <Switch>
              <Route exact path="/signin" component={SignIn} />
              <Route exact path="/forgotPassword" render={props => <ForgotPassword {...props} />} />
              <Route exact path="/confirmPassword/:token/" render={props => <ConfirmPassword {...props} />} />
              <Route exact path="/invitation/:token/" render={props => <Invitation {...props} />} />
              <Route exact path={`/review/:id`} component={ReviewForExport} />
              <Route exact path={`/review/:id/:size`} component={ReviewForExport} />
              <RestrictedRoute path={`${match.url}`} authUser={authUser} component={MainApp} />
            </Switch>
          </IntlProvider>
        </ConfigProvider>
      </>
    );
  }
}

const mapStateToProps = ({ settings, auth, team_users }) => {
  const { locale, navStyle, layoutType } = settings;
  const { authUser, initURL } = auth;
  const { currentUser } = team_users;
  const currentUserRole = currentUser?.items[0] && currentUser.items[0].role;
  const startOfWeek = currentUser?.items[0] && currentUser.items[0]?.clientSettings?.weekStart;
  return { locale, navStyle, layoutType, authUser, initURL, currentUserRole, startOfWeek };
};
export default connect(
  mapStateToProps,
  { setInitUrl, setThemeType, onNavStyleChange, onLayoutTypeChange }
)(App);
