import { createResource } from 'redux-rest-resource';
import { API_URL } from '../../config';
const url = `${API_URL}v2/api/generageResponseWithChatGpt`;
export const { types, actions, rootReducer } = createResource({
  name: 'chatGptResponse',
  url,

  actions: {
    fetch: {
      method: 'GET',
      transformResponse: res => ({
        res
      })
    }
  }
});
