import React from 'react';

import logo from 'assets/images/Localyser_Logo_Primary.png';

import style from './style.module.less';

export const Maintenance = () => (
  <>
    <div className={style.maintenanceLogoWrapper}>
      <img className={style.maintenanceLogo} src={logo} alt="localyser logo" />
    </div>
    <div className={style.maintenanceText}>
      <h1>Scheduled Maintenance</h1>
      <p>The system is currently undergoing maintenance from 9 AM to 11 AM Dubai time to help make it better. We apologies for the inconvenience.</p>
    </div>
  </>
);
