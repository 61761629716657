import { createResource } from 'redux-rest-resource';
import { API_URL } from './../../config';

const url = `${API_URL}v2/api/notifications/reviews`;

export const { types, actions, rootReducer } = createResource({
  name: 'notifications',
  url,

  actions: {
    fetch: {
      method: 'GET',
      transformResponse: res => ({
        res,
        body: res.body.data
      })
    },
    create: {
      method: 'POST'
    }
  }
});
