import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v2/api/admin/dashbord/oauth`;

export const { types, actions, rootReducer } = createResource({
  name: 'csdashboardOAuth',
  url,
  actions: {
    get: {
      method: 'GET',
      transformResponse: res => {
        return {
          res,
          body: res.body
        }
      }
    },
  }
});
