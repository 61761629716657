import React from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as confirmPasswordActions } from 'appRedux/resources/forgotPassword/confirmPassword';
import { notification } from 'antd';
import style from '../../style.module.less';


const ChangePassword = ({form, updateUserSettings, visible, onCancel})=>{
    // const [confirmDirty, setConfirmDirty] = useState(false)
    const {getFieldDecorator} = form;
    const onCloseForm =()=>{
        form.resetFields();
        onCancel();
    };
    const savePassword = (password)=> {
        updateUserSettings({ password: password })
        .then(()=>{
           notification.success({
            message: 'Password changed successfully.',
           });
           onCloseForm();
        }).catch(error => {
            return notification.error({
              message: 'Error',
             });
        });
      };
    const handleSubmit = e => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                if (values.password === values.confirm) {
                    if (values.password.length >= 6) {
                        savePassword(values.password);
                    } else {
                        return notification.error({
                            message: 'Password should be at least 6 characters.',
                        })
                    }

                }
            }
        });
    };
    const compareToFirstPassword = (rule, value, callback) => {
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };

    const validateToNextPassword = (rule, value, callback) => {
        if (value) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };
    return (
        <Modal
        title=""
        visible={visible}
        // onOk={()=>setIsChangePassword(false)}
        onCancel={onCloseForm}
        footer={null}
        >
        <Form onSubmit={handleSubmit} >
           
            <h2 className={style.forgotPasswordTitle}>
                Change password
             </h2>
                                        
                                       <Form.Item className={style.passwordChangeFormItem}>
                                            {getFieldDecorator('password', {
                                                      rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input your Password!'
                                                    },
                                                    {
                                                        validator: validateToNextPassword
                                                    }
                                                ]
                                            })(<Input.Password placeholder="New Password" />)}
                                        </Form.Item>
                                        <Form.Item className={style.passwordChangeFormItem}>
                                            {getFieldDecorator('confirm', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input your confirm password!'
                                                    },
                                                    {
                                                        validator: compareToFirstPassword
                                                    }
                                                ]
                                            })(<Input.Password placeholder="Confirm Password" />)}
                                        </Form.Item>
                                        <Form.Item className={style.passwordChangeButtonFormItem}>
                                            <Button type="primary" htmlType="submit">
                                                Confirm
                                            </Button>
                                        </Form.Item>
                                    </Form>                        
      </Modal>
        
    );

};
const WrappedChangePasswordForm = Form.create()(ChangePassword);

const mapStateToProps = state => {
    // const { loader, } = state.auth;
    return {
        // loader,
    };
};

export default connect(
    mapStateToProps,
    dispatch => ({
        ...bindActionCreators(confirmPasswordActions, dispatch),
    })
)(WrappedChangePasswordForm);

