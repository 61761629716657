import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v2/api/reviewCategory`;

export const { types, actions, rootReducer } = createResource({
    name: 'reviewCategory',
    url,

    actions: {
        fetch: {
            method: 'GET',
            transformResponse: res => ({
                res,
                body: res.body,
            })
        },
        create: {
            method: 'POST'
        },
        update: {
            method: 'PATCH',
        },
        delete: {
            method: 'DELETE'
        },
        deleteMany: {
            method: 'DELETE',
            isArray: true,
            alias: 'delete'
        }
    }
});