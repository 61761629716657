import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v2/api/password/fogot`;

export const { types, actions, rootReducer } = createResource({
  name: 'forgotPasswordMail',
  url,
  actions: {
    create: {
      method: 'POST'
    }
  }
});
