import React, { useState } from 'react';
import style from './style.module.less';
import DateRange from '../../../components/dataEntry/DateRange';
import { Button } from 'antd';
import moment from 'moment';
import { refreshToken } from 'appRedux/resources/changeUserTokens';
import { EXPORT_PARTNERS_BILLING } from 'config';
import { updateQueryUrl } from 'helpers/helpers';


const BillingExport = ({ apiType, partnerId, freeApi = false }) => {

    const [startDate, setStartDate] = useState(moment().subtract(7, 'days'));
    const [endDate, setEndDate] = useState(moment());
    const access_token = JSON.parse(localStorage.getItem('user') || '{}').access_token;

    const onDownload = () => {
        refreshToken().then(() => {

            let urlWithQuery = `${EXPORT_PARTNERS_BILLING}?access_token=${access_token}&freeApi=${freeApi}&id=${partnerId}&dateFrom=${startDate.format('YYYY-MM-DD')}&dateTo=${endDate.format('YYYY-MM-DD')}`;
            const urlWithUserRole = updateQueryUrl(urlWithQuery);
            const anchor = document.createElement('a');
            anchor.href = urlWithUserRole;
            anchor.setAttribute("download", 'export');
            anchor.style.display = "none";
            anchor.addEventListener('click', function (e) {
                e.stopPropagation();
            });
            document.body.appendChild(anchor);
            setTimeout(function () {
                anchor.click();
                document.body.removeChild(anchor);
            }, 66);

            return true;
        })
    }

    return (
        <div className={style.billingItemWrapper}>
            <h3 className={style.exportTitle}>{apiType}</h3>
            <DateRange
                startValue={startDate}
                endValue={endDate}
                onStartChange={setStartDate}
                onEndChange={setEndDate}
                classname={style.dateRange}
                todayYesterdayStyle={style.todayYesterdayStyle}
                extraFooter={false}
            />

            {partnerId &&
                <Button type='primary' className={style.exportButton} onClick={onDownload}>
                    Export
            </Button>}

        </div>

    );
};

export default BillingExport;