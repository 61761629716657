import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Drawer, Layout } from 'antd';
import { withRouter } from 'react-router-dom';
import { REVIEW_LIST, REVIEW_LIST_FILTER } from '../../constants/ViewMode';
import SidebarContent from './SidebarContent';
import SidebarLogo from './SidebarLogo';
import AppVersion from './AppVersion';
import { toggleCollapsedSideNav, updateWindowWidth } from 'appRedux/actions/Setting';
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE
} from '../../constants/ThemeSetting';
import UserProfile from './UserProfile';
import style from './sidebar.module.less';
import { getQueryParam } from 'helpers/helpers';
const { Sider } = Layout;

export class Sidebar extends Component {
  onToggleCollapsedNav = () => {
    this.props.toggleCollapsedSideNav(!this.props.navCollapsed);
  };

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.props.updateWindowWidth(window.innerWidth);
    });
  }
  getQueryParams = () => getQueryParam(this.props.location.search);
  getViewMode = () => {
    const queryParams = this.getQueryParams();
    const viewMode = queryParams.view;
    return viewMode;
  };

  render() {
    const { navCollapsed, width, navStyle, location } = this.props;
    const reviewUrl = location.pathname;
    const queryParams = this.getQueryParams();
    const viewMode = queryParams.view;

    let drawerStyle = 'gx-collapsed-sidebar';
    if (reviewUrl === '/reviews') {
      if (viewMode === REVIEW_LIST_FILTER || viewMode === REVIEW_LIST) {
        return null;
      }
    }
    if (navStyle === NAV_STYLE_FIXED) {
      drawerStyle = '';
    } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      drawerStyle = 'gx-mini-sidebar gx-mini-custom-sidebar';
    } else if (navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      drawerStyle = 'gx-custom-sidebar';
    } else if (navStyle === NAV_STYLE_MINI_SIDEBAR) {
      drawerStyle = 'gx-mini-sidebar';
    } else if (navStyle === NAV_STYLE_DRAWER) {
      drawerStyle = 'gx-collapsed-sidebar';
    }
    if (
      (navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) &&
      width < TAB_SIZE
    ) {
      drawerStyle = 'gx-collapsed-sidebar';
    }
    return (
      // <div className={style.sidebarWrapper}>
      <Sider
        className={`gx-app-sidebar ${drawerStyle} ${'gx-layout-sider-dark'} ${style.sidebarWrapper}`}
        trigger={null}
        collapsed={width < TAB_SIZE ? false : navStyle === NAV_STYLE_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR}
        theme={'dark'}
        collapsible
      >
        {navStyle === NAV_STYLE_DRAWER || width < TAB_SIZE ? (
          <Drawer
            wrapClassName={`gx-drawer-sidebar ${'gx-drawer-sidebar-dark'}`}
            placement="left"
            closable={false}
            onClose={this.onToggleCollapsedNav.bind(this)}
            visible={navCollapsed}
          >
            <>
              <SidebarLogo />
              <UserProfile classname={style.userProfile} />
              <SidebarContent />
              <AppVersion />
            </>
          </Drawer>
        ) : (
          <>
            <SidebarContent />
            <AppVersion />
          </>
        )}
      </Sider>
      // </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { themeType, navStyle, navCollapsed, width, locale } = settings;
  return { themeType, navStyle, navCollapsed, width, locale };
};
const connectHoc = connect(
  mapStateToProps,
  { toggleCollapsedSideNav, updateWindowWidth }
)(Sidebar);
const withRouterHoc = withRouter(connectHoc);
export default withRouterHoc;
