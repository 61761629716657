import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v2/api/admin/jira`;

export const { types, actions, rootReducer } = createResource({
    name: 'jira',
    url,

    actions: {
        saveApi: {
            method: 'POST',
        },
        saveList: {
            url:`${API_URL}v2/api/admin/jira/settings`,
            method: 'POST',
        },
        deleteApi: {
            method: 'DELETE',
        },
    }
});
