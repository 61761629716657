import React from 'react';
import { ConnectedRouter } from 'react-router-redux';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import 'assets/vendors/style';
import 'styles/wieldy.less';
import configureStore, { history } from './appRedux/store';
import App from './containers/App/index';
import { isPRODUCTION, isLOCALHOST } from 'config';

let curentPathname = '';
isPRODUCTION && history.listen(location => {
  if (curentPathname !== location.pathname) {
    curentPathname = location.pathname;
    const curentTitle = location.pathname.split('/');
    const titles = curentTitle.length > 1 &&
      curentTitle.map(path => path.length > 0 ? path.replace(/^./, path[0].toUpperCase()) : '').filter(title => title !== '').join('/');
    window.gtag('event', 'screen_view', {
      'app_name': 'Localyser',
      'screen_name': titles
    });
    window.gtag('config', 'G-CHTXDW25LK', {
      'page_title': titles,
      'page_path': location.pathname,
    });
  }
});

isLOCALHOST && (() => {
  // create a new instance of `MutationObserver` named `observer`,
  // passing it a callback function
  const observer = new MutationObserver((mutations) => {
    const elem = mutations.pop().addedNodes[0];

    if(elem && elem.tagName === 'IFRAME') {
      if (!elem.classList.contains('html2canvas-container')) {
        console.log("ANNOYING IFRAME DETECTED, killing it!");
        elem.remove();
      }
    }
  });

  // call `observe()`, passing it the element to observe, and the options object
  observer.observe(document.body, {
    subtree: false,
    childList: true,
  });
})();

export let store;

export const storePromise = new Promise(function (resolve, reject) {
  if (!document.location.search.includes('exportForPDF')) {
    store = configureStore({});
    return resolve(store);
  }

  const loadingInterval = setInterval(() => {
    if (!window.REDUX_DATA) {
      return;
    }
    clearInterval(loadingInterval);
    store = configureStore(window.REDUX_DATA);
    resolve(store);
  }, 300);
});

const NextApp = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </ConnectedRouter>
  </Provider>
);

export default NextApp;
