import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v2/api/admin/twitter/oauth`;

export const { types, actions, rootReducer } = createResource({
  name: 'twitterApi',
  url,
  actions: {
    getLink: {
      url: `${API_URL}v2/api/admin/twitter/oauth/link`,
      method: 'GET'
    },
    auth: {
      url: `${API_URL}v2/api/admin/twitter/oauth/accessToken`,
      method: 'POST'
    },
    createWebhook: {
      url: `${API_URL}v2/api/twitter/webhook`,
      method: 'POST'
    }
  }
});
