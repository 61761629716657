import { createResource } from 'redux-rest-resource';
import { API_URL } from '../../config';

export const { types, actions, rootReducer } = createResource({
  name: 'reaction',
  actions: {
    fetch: {
      method: 'POST',
      url: `${API_URL}v2/api/reaction/get`,
      transformResponse: res => {
        return {
          res,
          body: res.body
        };
      }
    }
  }
});
