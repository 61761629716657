import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v2/api/admin/packages`;

export const { types, actions, rootReducer } = createResource({
    name: 'packages',
    url,

    actions: {
        fetch: {
            method: 'GET',
            transformResponse: res => {
                return {
                    res,
                    body: res.body,
                }
            }
        },
        update: {
            method: 'PATCH'
        },

    }
});
