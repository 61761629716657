import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v2/api/team/:id`;

export const { types, actions, rootReducer } = createResource({
  name: 'teamUser',
  url,

  actions: {
    fetch: {
      method: 'GET',
      transformResponse: res => ({
        res,
        body: res.body.data,
        paginationTeamUsers: {
          total_teamUsers_count: res.body.total,
          page: res.body.page,
          per_page: res.body.per_page,
          total_pages: res.body.total_pages
        }
      })
    },
    create: {
      method: 'POST'
    },
    update: {
      method: 'PATCH'
    },
    delete: {
      method: 'DELETE'
    },
    deleteMany: {
      method: 'DELETE',
      isArray: true,
      alias: 'delete' // to have `deleteUsers` vs. `deleteManyUsers`
    }
  }
});
