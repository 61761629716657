import React, { useState, useCallback } from 'react';
import { Select, DatePicker, Spin } from 'antd';
import style from './style.module.less';
import PackageSettings from './PackageSettings';
import { useEffect } from 'react';
import moment from 'moment';
import Buttons from '../Butons';

const { Option } = Select;

const settingsTitles = {
  reviewFilterSearch: 'Review Filter & Search',
  directResponse: 'Direct Response',
  feedback: 'Feedback',
  reviewCategorization: 'Review Categorization',
  caseResolution: 'Case Resolution',
  competitorBenchmarking: 'Competitor Benchmarking',
  autoresponse: 'Autoresponse',
  messaging: 'Messaging',
  insightsReport: 'Insights Report',
  сhatGPTAutoResponseGeneration: 'ChatGPT Auto-response Generation'
};
const packageSettings = [
  { title: 'Review Filter & Search', settingName: 'reviewFilterSearch' },
  { title: 'Direct Response', settingName: 'directResponse' },
  { title: 'Feedback', settingName: 'feedback' },
  { title: 'Review Categorization', settingName: 'reviewCategorization' },
  { title: 'Case Resolution', settingName: 'caseResolution' },
  { title: 'Competitor Benchmarking', settingName: 'competitorBenchmarking' },
  { title: 'Autoresponse', settingName: 'autoresponse' },
  { title: 'Messaging', settingName: 'messaging' },
  { title: 'Insights Report', settingName: 'insightsReport' },
  { title: 'ChatGPT Auto-response Generation', settingName: 'сhatGPTAutoResponseGeneration' }
];

const Packages = ({
  currentuserRole,
  allPackages,
  isCompaniesFetching,
  currentPackage,
  packageChangeDate,
  period,
  onBack,
  onNext,
  updatePackage,
  currentFeatures,
  onPackageChangeDate
}) => {
  const [selectedPackage, setSelectedPackage] = useState('');
  const handleChange = useCallback(
    value => {
      const selected = allPackages && allPackages.find(company_package => company_package && company_package.name === value);
      setSelectedPackage(selected);
      currentPackage !== value && value !== 'Custom' && updatePackage(value);
    },
    [allPackages, currentPackage, updatePackage]
  );

  useEffect(() => {
    !selectedPackage && currentPackage && allPackages.length > 0 && handleChange(currentPackage);
  }, [selectedPackage, currentPackage, allPackages, handleChange]);

  const date = currentPackage === 'Free trial' ? moment(period) : packageChangeDate ? moment(packageChangeDate) : packageChangeDate;

  const changePackageSettings = (settingName, settingValue) => {
    let settings;
    if (currentPackage !== 'Custom') {
      settings = { ...selectedPackage.settings, [settingName]: settingValue };
      setSelectedPackage({ name: 'Custom', settings: settings });
      updateCustomPackageSettings(settings);
    } else {
      const selectedSettingTitle = packageSettings.find(setting => setting.settingName === settingName).title;
      const updatedFeatures = settingValue
        ? [...currentFeatures, selectedSettingTitle]
        : currentFeatures.filter(feature => feature !== selectedSettingTitle);
      updatePackage('Custom', updatedFeatures.join());
    }
  };

  const updateCustomPackageSettings = settings => {
    const settingsnames = Object.keys(settings);
    const settingsNamesWithValue = settingsnames.map(setting => ({ name: setting, value: settings[setting] }));
    const turnOnFeatchers = settingsNamesWithValue.filter(featcher => featcher.value);
    const featchers = turnOnFeatchers.map(setting => settingsTitles[setting.name]);
    updatePackage('Custom', featchers.join());
  };

  return (
    <div>
      {isCompaniesFetching && <Spin className={style.spinner} />}
      <div className={style.selecPackageDatePickertWrapper}>
        <div className={style.selectPackage}>
          <span className={style.label}>Select package</span>
          <Select value={selectedPackage && selectedPackage.name} style={{ width: 210 }} onChange={handleChange}>
            {allPackages.map((company_package, index) => (
              <Option value={company_package.name} disabled={company_package.name === 'Custom'} key={company_package.name + index}>
                {company_package.name}
              </Option>
            ))}
          </Select>
        </div>
        <div>
          <span className={style.label}>{selectedPackage && selectedPackage.name === 'Free trial' ? 'Expiry date' : 'Created date'}</span>
          <DatePicker onChange={onPackageChangeDate} format="YYYY/MM/DD" value={date} showToday={false} />
        </div>
      </div>
      {selectedPackage && selectedPackage.settings && currentuserRole !== 'Partner' && (
        <PackageSettings settings={selectedPackage.settings} features={currentFeatures} changePackageSettings={changePackageSettings} />
      )}
      <Buttons onBack={onBack} onNextToCompanies={onNext} nextButtonTitle="Next" />
    </div>
  );
};

export default Packages;
