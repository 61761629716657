import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v2/api/admin/mailchimp`;

export const { types, actions, rootReducer } = createResource({
    name: 'mailChimp',
    url,

    actions: {
        saveApi: {
            method: 'POST',
        },
        deleteApi: {
            method: 'DELETE',
        },
        saveCotactId: {
            url: `${API_URL}v2/api/admin/mailchimp/list`,
            method: 'POST',
        },
    }
});
