
import React,  {useEffect, useState} from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';

import { ReactComponent as FeedbackIcon } from 'assets/images/additionalSources/feedback.svg';
import defaultUser from 'assets/images/user.png';
import oneStar from 'assets/images/rate/oneStar.png';
import twoStar from 'assets/images/rate/twoStar.png';
import threeStar from 'assets/images/rate/threeStar.png';
import fourStar from 'assets/images/rate/fourStar.png';
import fiveStar from 'assets/images/rate/fiveStar.png';

import style from './style.module.less';


const ReviewForExport = ({ reviewForExport, match}) => {
  const [ imageUrl, setImageUrl ] = useState(null);
    useEffect(()=>{
        const reviewId = match.params.id;
        const imageSize = match.params.size;
        if (imageSize) {
          const url = `https://staging-api.localyser.com/review/${reviewId}_${imageSize}.png`;
          setImageUrl(url);
        }
    },[ match.params.id, match.params.size ])
  const currenteSource = reviewForExport?.service?.toLowerCase();
  const sourceLogo = currenteSource && require(`assets/images/additionalSources/${currenteSource}.png`);

  const findRating = rate => {
    let url;
    switch (rate) {
      case 1:
        url = oneStar;
        break;
      case 2:
        url = twoStar;
        break;
      case 3:
        url = threeStar;
        break;
      case 4:
        url = fourStar;
        break;
      case 5:
        url = fiveStar;
        break;
      default:
        url = fiveStar;
    }
    return (
      <img
        src={url}
        style={{
          width: '132px',
          height: '26px'
        }}
        className={style.reviewRate}
        alt="rate"
      />
    );
  };
 

  return (
    <>
      {imageUrl ?
          <div className={style.pageWrapper}>
            <img src={imageUrl} alt="review img" />
          </div> :
       reviewForExport  ? 
      <div className={classnames(style.reviewForSharingWrapper,(reviewForExport.sharingSource === 'Facebook' || reviewForExport.sharingSource === 'Instagram') && style.reviewForSharingVerticalWrapper )} id="shareReviewForBackend">
        <div className = {classnames(style.reviewerForSharingContent,(reviewForExport.sharingSource === 'Facebook' || reviewForExport.sharingSource === 'Instagram') && style.verticalLayought )}>
          <div className={style.reviewerProfileWrapper}>
            <img crossOrigin="anonymous" src={defaultUser} className={style.userIcon} alt="user_icon" />
            <p className={style.reviever}>{reviewForExport.name}</p>
            <p className={style.reviewDate}>{reviewForExport.date}</p>
            {findRating(+reviewForExport.rating_value)}
          </div>
          {reviewForExport.review_text && <p className={classnames(style.reviewText, (reviewForExport.sharingSource === 'Facebook' || reviewForExport.sharingSource === 'Instagram') && style.verticalText)}>"{reviewForExport.review_text}"</p>}
        </div>
        <div className={style.locationSourceWrapper}>
          <div className={style.locationWrapper}>
            <p className={style.locationName}>{reviewForExport.location_name}</p>
            <p className={style.locationId}>{reviewForExport.location_location_id}</p>
          </div>
          {reviewForExport.service !== 'Feedback' ? (
            <img src={sourceLogo} alt="service logo" width="40px" height="auto" />
        ) : (
          <FeedbackIcon width="37px" height="35px" />
          )}
        </div>
    </div> : <p className={style.noDataTitle}>No data</p>}
    </>
  );
};

const MSTP = state => {
  
  return {
    reviewForExport: state.reviewsAll.reviewForExport.items[0]
  };
};

export default connect(
  MSTP,null
)(ReviewForExport);

