import React from 'react';
import { Checkbox } from 'antd';
import style from './style.module.less';
// import { DEFAULT_COMPANY_SOURCES } from 'constants/Sources';
import { isPRODUCTION } from 'config';

const CompetitorSources = ({ onCompetitoeSourceChange, selectedCompetitorsSources, sources, additionalCompanySources }) => {
  const competitorsources =
    // (isLocalhost || isStaging) ?
    //

    sources.filter(source => source !== 'Feedback');
  // :
  // sources.filter(source => {
  //   //need to hide  additional sources before competitors settings will not done
  //   const isSourceDefault = DEFAULT_COMPANY_SOURCES.find(default_source => default_source.toUpperCase() === source.toUpperCase());
  //   return isSourceDefault && source !== 'Feedback' && source;
  // });

  const onSourcesChange = e => {
    const selectedSourceName = e.target.dataName;
    const checkedValue = e.target.checked;
    const selectedSources = checkedValue
      ? [...selectedCompetitorsSources, e.target.dataName]
      : selectedCompetitorsSources.filter(source => source !== selectedSourceName);
    onCompetitoeSourceChange(selectedSources);
  };

  return (
    <div>
      <h2 className={style.competitorsSourcesTitle}>
        Competitor sources <span className={style.maxCompetitorceSources}>4 Max.</span>{' '}
      </h2>
      <div className={style.companyCompetitorsSources} style={isPRODUCTION ?  { height: '170px' } : { height: '500px' }}>
        {competitorsources.map(service => (
          <div className={style.sourcesCheckBox} key={service}>
            <Checkbox
              checked={selectedCompetitorsSources.includes(service)}
              defaultChecked={selectedCompetitorsSources.includes(service)}
              dataName={service}
              onChange={e => onSourcesChange(e)}
              disabled={selectedCompetitorsSources.length === 4 && !selectedCompetitorsSources.includes(service)}
            >
              {service}
            </Checkbox>
          </div>
        ))}
      </div>
      {/* <div className={style.companyAdditionalSources}>
        {additionalCompanySources.map(service => (
          <div className={style.sourcesCheckBox}>
            <Checkbox value={service}>{service}</Checkbox>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default CompetitorSources;
