import React, { Component } from 'react';
import { Layout } from 'antd';
import Sidebar from '../Sidebar/index';
import App from 'routes/index';
import { connect } from 'react-redux';
import style from './mainApp.module.less';
import axios from 'axios';
import { API_URL } from '../../config';

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE
} from '../../constants/ThemeSetting';
import { toggleCollapsedSideNav } from 'appRedux/actions/Setting';

import { bindActionCreators } from 'redux';
import { actions as currentUserActions } from 'appRedux/resources/currentUser/currentUserResources';
import { isPRODUCTION } from 'config';
import MainHeader from '../../routes/Feedback/LandingPage/LandingPageEditor/MainHeader';

const { Content } = Layout;

const PDF_EXPORT_MODE = () => !!window.REDUX_DATA;

export class MainApp extends Component {
  componentDidMount() {
    if (!PDF_EXPORT_MODE()) {
      this.sendUserAndCompanyPropertiesToGAandUserGuiging();
    }
    window.addEventListener('resize', this.updatePageHeight);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updatePageHeight);
  }

  updatePageHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  sendUserAndCompanyPropertiesToGAandUserGuiging = () => {
    if (this.props.currentUser) {
      this.saveDataToUserGuidingAndGA(this.props.currentUser);
    } else {
      this.props.fetchCurrentUsers().then(data => {
        // const ownerAsCompanyId = JSON.parse(localStorage.getItem('ownerAsCompany') || '{}').id;
        // const ownerAsPartnerId = JSON.parse(localStorage.getItem('ownerAsPartner') || '{}').id;
        // const adminAsUserId = JSON.parse(localStorage.getItem('adminAsUser') || '{}').id;
        // if (ownerAsCompanyId || ownerAsPartnerId) {
        //   this.props.updateSettingsCurrentUser({ id: ownerAsCompanyId }, { query: null });
        // }else if (adminAsUserId){
        //   this.props.updateSettingsCurrentUser({ id: adminAsUserId }, { query: null });
        // }
        const currentUser = data && (data.body ? data.body : data.res.body);
        this.loadUserlistScript(currentUser.id);
        this.saveDataToUserGuidingAndGA(currentUser);
      });
    }
  };

  loadUserlistScript = async (userId) => {
    try {
      const token = JSON.parse(localStorage.getItem('user') || '{}').access_token;

      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      };

      const response = await axios.get(`${API_URL}v2/api/userlist/token?id=${userId}`, config);

      if (response.data) {
        window.userlist = window.userlist || function () { (window.userlist.q = window.userlist.q || []).push(arguments) };

        if (userId && window.userlist) {
          const script = document.createElement('script');
          script.async = true;
          script.setAttribute('data-userlist', response.data);
          script.src = 'https://js.userlist.com/v1';
          document.body.appendChild(script);
        }
      }
    } catch (error) {

    }
  };

  saveDataToUserGuidingAndGA = curentUser => {
    const userSubscriptionPackage = (curentUser && curentUser.package) || '';
    const curentUserId = (curentUser && curentUser.email) || 'No data';
    const curentUserRole = (curentUser && curentUser.role) || 'No data';
    const curentUserCompany = (curentUser && curentUser.companyName) || 'No data';
    const currentUserCompanyType = (curentUser && curentUser.comanyType) || 'No data';
    const currentUserCompanyCity = (curentUser && curentUser.companyCity) || 'No data';
    const currentUserCompanyState = (curentUser && curentUser.companyState) || 'No data';
    const currentUserCompanyCountry = (curentUser && curentUser.companyCountry) || 'No data';
    // isUserPropertiesSend = true;
    isPRODUCTION && window.gtag('config', 'G-CHTXDW25LK', { userId: curentUserId });
    isPRODUCTION &&
      window.gtag('set', 'user_properties', {
        user: curentUserId,
        role: curentUserRole,
        company_name: curentUserCompany,
        company_type: currentUserCompanyType,
        company_city: currentUserCompanyCity,
        company_state: currentUserCompanyState,
        company_country: currentUserCompanyCountry,
        company_package: userSubscriptionPackage
      });

    if (curentUserId && isPRODUCTION) {
      window.userGuidingUserId = curentUserId;
    }
  };

  getContainerClass = navStyle => {
    switch (navStyle) {
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return 'gx-container-wrap';
      default:
        return '';
    }
  };
  getNavStyles = navStyle => {
    switch (navStyle) {
      default:
        return null;
    }
  };

  onToggleCollapsedNav = () => {
    this.props.toggleCollapsedSideNav(!this.props.navCollapsed);
  };

  render() {
    const { match, navStyle, currentUser, history, location } = this.props;
    const ownerLikeUser = JSON.parse(localStorage.getItem('ownerAsUser') || '{}').userName;
    const ownerAsPartner = JSON.parse(localStorage.getItem('ownerAsPartner') || '{}').userName;
    const partnerLikeUser = JSON.parse(localStorage.getItem('partnerAsUser') || '{}').userName;
    const adminLikeUser = JSON.parse(localStorage.getItem('adminAsUser') || '{}').userName;
    return (
      <div className={style.mainWrapper}>
        <MainHeader
          currentUser={currentUser}
          ownerLikeUser={ownerLikeUser}
          ownerAsPartner={ownerAsPartner}
          history={history}
          partnerLikeUser={partnerLikeUser}
          adminLikeUser={adminLikeUser}
          onToggleCollapsedNav={this.onToggleCollapsedNav}
          navCollapsed={this.navCollapsed}
          location={location}
        />
        {currentUser ? (
          <Layout className="gx-app-layout">
            {!PDF_EXPORT_MODE() && <Sidebar className={style.mainSidebar} />}
            <Layout>
              <Content className={`gx-layout-content ${this.getContainerClass(navStyle)} `}>
                <App match={match} currentUser={currentUser} />
              </Content>
            </Layout>
          </Layout>
        ) : (
          <div />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { width, navStyle, navCollapsed } = state.settings;
  return {
    width,
    navStyle,
    navCollapsed,
    currentUser: state.team_users.currentUser.items[0]
  };
};
const MDTP = dispatch => {
  return {
    ...bindActionCreators(currentUserActions, dispatch),
    toggleCollapsedSideNav: toggleData => {
      dispatch(toggleCollapsedSideNav(toggleData));
    }
  };
};
export default connect(
  mapStateToProps,
  MDTP
)(MainApp);
