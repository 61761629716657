import { createResource } from 'redux-rest-resource';

import { API_URL } from 'config';

export const { types, actions, rootReducer } = createResource({
  name: 'reputationScore',

  actions: {
    fetch: {
      method: 'GET',
      url: `${API_URL}v2/api/dashboard/reputationScore`,

      transformResponse: res => ({
        res,
        body: res.body.data
      })
    }
  }
});
