import { createResource } from 'redux-rest-resource';
import { API_URL } from '../../../config';

const url = `${API_URL}v2/api/lendingPage/brand`;

export const { types, actions, rootReducer } = createResource({
    name: 'landingPageBrand',
    url,

    actions: {
        fetch: {
            method: 'GET',
            transformResponse: res => ({
                res,
                body: res.body.data,
                paginationLandingPageBrands: {
                    total_locations_count: res.body.total,
                    page: res.body.page,
                    per_page: res.body.per_page,
                    total_pages: res.body.total_pages
                }
            })
        },

    }
});
