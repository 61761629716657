import React from 'react';
import { Button } from 'antd';
import style from './style.module.less';

const CompanySettingsHeader = ({ editedCompany, classname, showMenuItem, onClick, isCompanyBrands }) => {
  return (
    <div className={classname}>
      <h2>{editedCompany ? `Company ${editedCompany.company_name} settings` : 'Company settings'}</h2>
      {showMenuItem('brands') &&
        (editedCompany !== '' && (
          <Button className={style.addBrandButton} onClick={onClick}>
            Add brand
          </Button>
        ))}
    </div>
  );
};

export default CompanySettingsHeader;
