import { createResource } from 'redux-rest-resource';
import { API_URL } from '../../config';

export const { types, actions, rootReducer } = createResource({
  name: 'webhookSettings',
  actions: {
    fetch: {
      method: 'GET',
      url: `${API_URL}v2/api/webhook_settings`,
      transformResponse: res => {
        return {
          res,
          body: res.body.data
        };
      }
    },
    create: {
      method: 'POST',
      url: `${API_URL}v2/api/webhook_settings`,
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          if (action.status === 'rejected') {
            return {
              ...state,
              isUpdating: false
            };
          }
          return {
            ...state,
            isUpdating: true,
            verifyError: false
          };
        }
        if (action.body !== '') {
          return { ...state, items: [action.body.data], isUpdating: false };
        }
        return { ...state, items: state.items, isUpdating: false };
      }
    },
    verify: {
      method: 'POST',
      url: `${API_URL}v2/api/webhook_settings/verify`,
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          if (action.status === 'rejected') {
            return {
              ...state,
              isUpdating: false,
              verifyError: true
            };
          }
          return {
            ...state,
            isUpdating: true,
            verifyError: false
          };
        }
        if (action.body !== '') {
          return { ...state, items: [action.body.data], isUpdating: false, verifyError: false };
        }
        return { ...state, items: state.items, isUpdating: false, verifyError: false };
      }
    },
    test: {
      method: 'POST',
      url: `${API_URL}v2/api/webhook_settings/test`,
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          if (action.status === 'rejected') {
            return {
              ...state,
              isUpdating: false,
              testError: true
            };
          }
          return {
            ...state,
            isUpdating: true,
            testError: false
          };
        }
        if (action.body !== '') {
          return { ...state, isUpdating: false, testError: false };
        }
        return { ...state, items: state.items, isUpdating: false, testError: false };
      }
    }
  }
});
