import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { USER_ROLES } from '../../constants/Role';

const GuardedFeaturesRoute = ({ component: Component, features, feature, role, ...rest }) => {
  if (features?.length && role) {
    return (
      <Route
        {...rest}
        render={props =>
          (features.includes(feature) && (role === USER_ROLES.Owner || role === USER_ROLES.Partner)) ||
          (features.includes(feature) && (role !== USER_ROLES.Owner || role !== USER_ROLES.Partner)) ||
          role === USER_ROLES.Owner ? (
            <Component {...props} />
          ) : (
            <Redirect to={'/reviews'} />
          )
        }
      />
    );
  }

  if (features?.length) {
    return <Route {...rest} render={props => (features.includes(feature) ? <Component {...props} /> : <Redirect to={'/reviews'} />)} />;
  }
  return (
    <Route
      {...rest}
      render={props => (role === USER_ROLES.Owner || role === USER_ROLES.Partner ? <Component {...props} /> : <Redirect to={'/reviews'} />)}
    />
  );
};

export default GuardedFeaturesRoute;
