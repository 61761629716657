import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions as currentUserActions } from 'appRedux/resources/currentUser/currentUserResources';
import { actions as userSettingsActions } from 'appRedux/resources/currentUser/userSettings';

import { Button, Form, Tooltip, Icon, Input } from 'antd';
import SaveEditButton from './components/SaveEditButton';
import style from './style.module.less';
import ChangePassword from './components/ChangePassword';

const UserSettings = ({ currentUser, form, updateUserSettings, fetchCurrentUsers }) => {
  const [editedSetting, setEdditedSetting] = useState(null);
  const [isSettingSaving, setIsSettingSaving] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);

  const formLayout = 'horizontal';
  const { getFieldDecorator, getFieldValue } = form;
  const formItemLayout =
    formLayout === 'horizontal'
      ? {
          labelCol: { span: 6 },
          wrapperCol: { span: 18 }
        }
      : null;

  const editSetting = e => {
    setEdditedSetting(e.currentTarget.dataset.name);
  };
  const saveSetting = e => {
    const editedSettingValue = getFieldValue(editedSetting);
    if (currentUser[editedSetting] === editedSettingValue) {
      setIsSettingSaving(false);
      setEdditedSetting(null);
    } else {
      form.validateFields((err, values) => {
        if (!err) {
          setIsSettingSaving(true);
          updateUserSettings({ [editedSetting]: editedSettingValue }).then(() => {
            fetchCurrentUsers().then(() => {
              setIsSettingSaving(false);
              setEdditedSetting(null);
            });
          });
        }
      });
    }
  };

  const onCancelChanging = () => {
    const { setFieldsValue } = form;
    setFieldsValue({ [editedSetting]: currentUser[editedSetting] });
    setEdditedSetting(null);
  };

  return (
    <div className={style.userSettingsMain}>
      <h1>User Settings</h1>
      <Form {...formItemLayout} labelAlign="left" className={style.currentUserAccessForm}>
        <Form.Item label="First Name" className={style.formItem}>
          <div className={style.settingWrapper}>
            {getFieldDecorator('firstName', {
              initialValue: currentUser ? currentUser.firstName : null
            })(<Input placeholder="First name" type="text" disabled={editedSetting !== 'firstName'} />)}
            <SaveEditButton
              saveEditButton={style.saveEditButton}
              saveSetting={saveSetting}
              isSettingSaving={isSettingSaving}
              editSetting={editSetting}
              editedSetting={editedSetting}
              settingName="firstName"
              onCancelChanging={onCancelChanging}
            />
          </div>
        </Form.Item>

        <Form.Item label="Last Name" className={style.formItem}>
          <div className={style.settingWrapper}>
            {getFieldDecorator('lastName', {
              initialValue: currentUser ? currentUser.lastName : null
            })(<Input type="text" placeholder="Last name" disabled={editedSetting !== 'lastName'} />)}
            <SaveEditButton
              saveEditButton={style.saveEditButton}
              saveSetting={saveSetting}
              isSettingSaving={isSettingSaving}
              editSetting={editSetting}
              editedSetting={editedSetting}
              settingName="lastName"
              onCancelChanging={onCancelChanging}
            />
          </div>
        </Form.Item>

        <Form.Item
          label={
            <span>
              Email&nbsp;
              <Tooltip title="Enter the work email of this user, an email will be sent to to setup their password.">
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
          }
          className={style.formItem}
        >
          <div className={style.settingWrapper}>
            {getFieldDecorator('email', {
              rules: [
                {
                  required: true,
                  message: 'Email is required!'
                },
                {
                  type: 'email',
                  message: 'Email is not valid!'
                }
              ],
              initialValue: currentUser ? currentUser.email : null
            })(<Input placeholder="Email" type="text" disabled={editedSetting !== 'email'} />)}
            <SaveEditButton
              saveEditButton={style.saveEditButton}
              saveSetting={saveSetting}
              isSettingSaving={isSettingSaving}
              editSetting={editSetting}
              editedSetting={editedSetting}
              settingName="email"
              onCancelChanging={onCancelChanging}
            />
          </div>
        </Form.Item>

        <Form.Item className={style.changePassword}>
          <Button type="primary" onClick={() => setIsChangePassword(true)}>
            Change password
          </Button>
        </Form.Item>
        <Form.Item>
          <div className={style.buttonsWrapper}>
            <Link to="/reviews">
              <span className={style.goBackButton}>Back</span>
            </Link>
          </div>
        </Form.Item>
      </Form>
      <ChangePassword updateUserSettings={updateUserSettings} visible={isChangePassword} onCancel={() => setIsChangePassword(false)} />
    </div>
  );
};
const WrappedUserSettings = Form.create({ name: 'user_settings' })(UserSettings);
export default connect(
  state => ({
    currentUser: state.team_users.currentUser.items[0]
  }),
  dispatch => ({
    ...bindActionCreators(userSettingsActions, dispatch),
    ...bindActionCreators(currentUserActions, dispatch)
  })
)(WrappedUserSettings);
