import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import { connect } from 'react-redux';
import { actions as partnersActions } from 'appRedux/resources/partners/partners';
import { bindActionCreators } from 'redux';

import style from './style.module.less';
import MenuPartners from '../MenuPartners';
import PartnerInformation from '../PartnerInformation';
import PartnerPackage from '../PartnerPackage';
import ApiKey from '../APIkey';
import PartnerBilling from '../PartnerBilling';
import { notification, Spin } from 'antd';


const AddPartner = ({ location, match, createPartner, updatePartner, history, fetchPartners, partners, isPartnersFetching, isPartnersCreating, isPartnersUpdating }) => {

    const [selectedMenuItem, setSelectedMenuItem] = useState('');
    const [editedPartner, setEditedPartner] = useState('');

    useEffect(() => {
        if (match.params.id) {
            if (!partners) {
                fetchPartners();
            }
            const editedPartner = partners && partners.find(partner => {
                return partner.id === +match.params.id
            });
            setEditedPartner(editedPartner);
        }
    }, [fetchPartners, match.params.id, partners]);

    useEffect(() => {
        if (location.pathname) {
            const pathname = window.location.pathname.split('/');
            const id = pathname[pathname.length - 1];
            let selected = isNaN(id) ? id : pathname[pathname.length - 2];
            if (selected !== selectedMenuItem) {
                setSelectedMenuItem(selected)
            };
        }

    }, [selectedMenuItem, location.pathname]);


    const handleClick = ({ key }) => setSelectedMenuItem(key);

    const createNewPartner = values => {
        return createPartner(values).then(response => {
            setEditedPartner(response.body);
            onChangeMenuItem('package')
        }).catch(error => {
            const errorDescription = error.body && error.body;
            notification.error({
                message: errorDescription ? errorDescription : 'Error',
            });
        });
    };

    const isValuesChanged = values => {
        const valuesFields = Object.keys(values);
        let changedValuesFields = {};

        valuesFields.forEach(fieldName => {
            if (editedPartner && (fieldName !== 'settings' && (editedPartner[fieldName] !== values[fieldName]))) {
                changedValuesFields[fieldName] = values[fieldName]
            }
            if (!editedPartner && (fieldName !== 'settings' && values[fieldName])) {
                changedValuesFields[fieldName] = values[fieldName]
            }
            if ((values && values.settings && values.settings.packageCredits && ((editedPartner.settings && editedPartner.settings.packageCredits !== values.settings.packageCredits) || !editedPartner.settings))) {
                changedValuesFields.settings = values.settings;
            }
        })

        return Object.keys(changedValuesFields).length > 0 ? changedValuesFields : null;
    };

    const updateCreatedPartner = (values, packagesTab) => {

        const changedValuesFields = isValuesChanged(values);
        if (changedValuesFields) {
            updatePartner({ id: editedPartner.id, ...changedValuesFields }).then(response => {
                setEditedPartner(response.body[0]);
                onChangeMenuItem(packagesTab);
            }).catch(error => {
                const errorDescription = error.body && error.body;
                notification.error({
                    message: errorDescription ? errorDescription : 'Error',
                });
            });
        } else {
            onChangeMenuItem(packagesTab);
        }
    }

    const onBackToPartners = () => history.push('/admin_panel/partners');

    const onChangeMenuItem = tabName => history.push(`${match.url}/${tabName}`);

    const buttonDisabled = (isPartnersFetching || isPartnersCreating || isPartnersUpdating);

    return (
        <div className={style.pageWrapper}>
            <h2 className={style.pageHeader}>{editedPartner && editedPartner.name ? `${editedPartner.name} settings` : 'Partner settings'}</h2>
            <MenuPartners match={match} selectedMenuItem={selectedMenuItem} handleClick={handleClick} setSelectedMenuItem={setSelectedMenuItem} />
            {(isPartnersFetching || isPartnersCreating || isPartnersUpdating) && <Spin className={style.spinner} />}
            <Switch>
                <Route path={`${match.url}/information`} exact render={props =>
                    <PartnerInformation
                        createNewPartner={createNewPartner}
                        updateCreatedPartner={updateCreatedPartner}
                        onBackToPartners={onBackToPartners}
                        editedPartner={editedPartner}
                        isValuesChanged={isValuesChanged}
                        nextButtonStyle={style.nextButtonStyle}
                        buttonDisabled={buttonDisabled}
                        {...props}
                    />} />
                <Route path={`${match.url}/package`} exact render={props =>
                    <PartnerPackage
                        onBack={() => onChangeMenuItem('information')}
                        editedPartner={editedPartner}
                        updateCreatedPartner={updateCreatedPartner}
                        isValuesChanged={isValuesChanged}
                        nextButtonStyle={style.nextButtonStyle}
                        buttonDisabled={buttonDisabled}
                    />} />
                <Route path={`${match.url}/apiKey`} exact render={props =>
                    <ApiKey
                        // onBack={() => onChangeMenuItem('package')}
                        apiKey={editedPartner && editedPartner.apiKey && editedPartner.apiKey}
                        nextButtonStyle={style.nextButtonStyle}
                    />} />
                <Route path={`${match.url}/billing`} exact render={props =>
                    <PartnerBilling
                        editedPartnerId={editedPartner && editedPartner.id}
                    />} />
            </Switch>

        </div>
    );
};

const MSTP = state => {
    const partners = state.admin.partners.items && state.admin.partners.items[0] && state.admin.partners.items[0].partners;
    return ({
        partners,
        isPartnersFetching: state.admin.partners.isFetching,
        isPartnersCreating: state.admin.partners.isCreating,
        isPartnersUpdating: state.admin.partners.isUpdating,
    })
}
export default connect(
    MSTP,
    dispatch => ({
        ...bindActionCreators(partnersActions, dispatch),
    })
)(AddPartner);
