import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v2/api/admin/facebook`;

export const { types, actions, rootReducer } = createResource({
  name: 'facebookApi',
  url,
  actions: {
    getFacebookAccounts: {
      url: `${API_URL}v2/api/admin/facebook/accounts`,
      method: 'POST'
    },
    update: {
      method: 'POST',
      reduce: (state, action) => {
        let response = {};
        if (action.status === 'resolved') {
          response = action.body;
        }
        return { ...state, items: [{ ...state.items[0], ...response }] };
      }
    },
    create: {
      method: 'POST',
      url: `${API_URL}v2/api/admin/facebook/create`,
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return state;
        }
        return { ...state };
      }
    },
    patch: {
      url: `${API_URL}v2/api/admin/facebook/pageIds`,
      method: 'PATCH'
    },
    combine: {
      url: `${API_URL}v2/api/admin/facebook/page`,
      method: 'POST'
    },
    getAccaunt: {
      url: `${API_URL}v2/api/admin/facebook/url`,
      method: 'POST',
      reduce: (state, action) => {
        if (action.status === 'resolved') {
          const accountId = action.context.accountId;
          const urlPart = action.body.urlPart;
          let url;
          let fullUrl;
          if (urlPart.includes('next')) {
            url = urlPart.split('next=');
            fullUrl = decodeURIComponent(url[1]);
          } else {
            fullUrl = 'https://www.facebook.com' + urlPart;
          }
          // const urlPartWithout = action.body?.urlPart?.split('/');
          let accountName;
          const updatedAccounts = state.items[0]?.accounts.map(account => {
            if (account.id === accountId) {
              accountName = account.id;
              return { ...account, urlPart: urlPart, fullUrl: fullUrl };
            } else {
              return account;
            }
          });
          const updatedLocations = state.items[0]?.locations?.map(companyLocation => {
            const initialValue = companyLocation.facebook.includes(fullUrl);
            return initialValue ? { ...companyLocation, initialValue: accountName } : companyLocation;
          });

          return { ...state, items: [{ accounts: updatedAccounts, locations: updatedLocations }] };
        }
        return state;
      }
    },
    updateToken: {
      url: `${API_URL}v2/api/admin/facebook/update`,
      method: 'POST'
    }
  }
});
