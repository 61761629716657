import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v2/api/conversations/messages/:id`;

export const { types, actions, rootReducer } = createResource({
  name: 'message',
  url,

  actions: {
    fetch: {
      method: 'GET',
      transformResponse: res => {
        return {
          res,
          body: res.body
        };
      }
    },
    send: {
      method: 'PATCH',
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return state;
        }
        const { message, sentAt, type, attachmentType } = action.context;
        let updatedItems = state.items[0];
        updatedItems.messages = [...updatedItems.messages, { type, sentAt, message, attachmentType }];
        return { ...state, items: [updatedItems] };
      }
    }
  }
});
