import React from 'react';
import { Button, Form, Input, message, Icon, Popover, Spin, notification } from 'antd';
import { connect } from 'react-redux';
import { hideMessage, showAuthLoader, userSignIn } from 'appRedux/actions/Auth';
import IntlMessages from 'util/IntlMessages';
import { isLOCALHOST, USERS } from '../config';
import logo from 'assets/images/Localyser_Logo_Primary.png';
import { Link } from 'react-router-dom';
import style from './signin.module.less';

const FormItem = Form.Item;

class SignIn extends React.Component {
  componentDidMount() {
    if (localStorage.getItem('fromConfirmPassword')) {
      localStorage.removeItem('fromConfirmPassword');
      return notification.success({
        message: 'You have successfully changed your password. Please sign in with your new password.'
      });
    }
    if (localStorage.getItem('fromInvitation')) {
      localStorage.removeItem('fromInvitation');
      return notification.success({
        message: 'You have successfully created a new password and completed your registration. Please sign in with your email and password.',
        duration: 5.5
      });
    }
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.props.showAuthLoader();
        await this.props.userSignIn(values);

        const { location } = this.props;

        if (location.state && location.state.from) {
          const { pathname, search } = location.state.from;
          return this.props.history.push(`${pathname}${search}`);
        }
        return this.props.history.push('/');
      }
    });
  };

  onUserChoose = async e => {
    const currentUserEmail = e.currentTarget.dataset.name;
    const currentUser = USERS.find(user => user.mail === currentUserEmail);
    const values = { email: currentUser.mail, password: currentUser.password };
    this.props.showAuthLoader();
    await this.props.userSignIn(values);
    this.props.history.push('/');
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { showMessage, loader, alertMessage, invitationUser } = this.props;
    const content = (
      <div>
        <ul>
          {USERS.map(user => {
            return (
              <li key={user.mail} data-name={user.mail} onClick={this.onUserChoose} style={{ cursor: 'pointer' }}>
                <span style={{ color: 'blue' }}>User:</span> {user.userName}
              </li>
            );
          })}
        </ul>
      </div>
    );
    const errorMsg = alertMessage?.data?.response?.data?.message ? alertMessage?.data?.response?.data?.message : alertMessage.toString();

    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className={style.logoWrapper}>
              <img src={logo} alt="Locolyser logo" className={style.logoStyle} />
              {isLOCALHOST && (
                <Popover content={content} title="Choose user (for developing tool)" trigger="hover">
                  <Icon type="question-circle-o" className={style.tooltipStyle} />
                </Popover>
              )}
            </div>
            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
                {/* <span>
                  <h2 className="gx-app-login-name">
                    Sign In{' '}
                    {(isStaging || isLocalhost) && (
                      <Popover content={content} title="Choose user (for developing tool)" trigger="hover">
                        <Icon type="question-circle-o" />
                      </Popover>
                    )}
                  </h2>
                </span> */}

                <FormItem>
                  {getFieldDecorator('email', {
                    initialValue: invitationUser.items.length ? invitationUser.items[0].email : null,
                    rules: [
                      {
                        required: true,
                        type: 'email',
                        message: 'Email is not valid!'
                      }
                    ]
                  })(<Input placeholder="Email" data-testid="email" />)}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Please input your Password!' }]
                  })(<Input.Password type="password" placeholder="Password" data-testid="password" />)}
                </FormItem>
                <FormItem>
                  {/* {getFieldDecorator('remember', {
                    valuePropName: 'checked',
                    initialValue: true
                  })(
                    <Checkbox>
                      <IntlMessages id="appModule.rememberMe" />
                    </Checkbox>
                  )} */}
                  <Link to="/forgotPassword">
                    <p className={style.forgotPassword}>Forgot password?</p>
                  </Link>
                </FormItem>
                <FormItem>
                  <Button disabled={loader} type="primary" className="gx-mb-0 gx-app-login-button" htmlType="submit" data-testid="submit-button">
                    <IntlMessages id="app.userAuth.signIn" />
                  </Button>
                </FormItem>
              </Form>
            </div>
            {loader ? (
              <div className="gx-loader-view">
                <Spin />
              </div>
            ) : null}
            {showMessage ? message.error(errorMsg) : null}
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = state => {
  const { loader, alertMessage, showMessage, authUser } = state.auth;
  return {
    loader,
    alertMessage,
    showMessage,
    authUser,
    invitationUser: state.invitation
  };
};

export default connect(
  mapStateToProps,
  {
    userSignIn,
    hideMessage,
    showAuthLoader
  }
)(WrappedNormalLoginForm);

// import React from 'react';
// import { Link } from 'react-router-dom';
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
// import { actions as authActions } from '../appRedux/resources/auth';

// import { CLIENT_ID, CLIENT_SECRET, USERS } from 'config';

// import { hideMessage, showAuthLoader, userSignIn } from 'appRedux/actions/Auth';
// import IntlMessages from 'util/IntlMessages';
// import logo from 'assets/images/logo-white.png';
// import { isStaging, isLocalhost } from '../config';
// import { Button, Form, Input, message, Icon, Popover, Spin, notification } from 'antd';

// import style from './signin.module.less';

// const FormItem = Form.Item;

// class SignIn extends React.Component {

//   componentDidMount() {
//     if (localStorage.getItem('fromConfirmPassword')) {
//       localStorage.removeItem('fromConfirmPassword')
//       return notification.success({
//         message: 'You have successfully changed your password. Please sign in with your new password.',
//       })
//     }
//     if (localStorage.getItem('fromInvitation')) {
//       localStorage.removeItem('fromInvitation')
//       return notification.success({
//         message: 'You have successfully created a new password and completed your registration. Please sign in with your email and password.',
//         duration: 5.5
//       })
//     }
//   }

//   componentDidUpdate() {
//     if (this.props.showMessage) {
//       setTimeout(() => {
//         this.props.hideMessage();
//       }, 100);
//     }
//   }

//   handleSubmit = e => {
//     const {form} = this.props;
//     e.preventDefault();
//     form.validateFields(async (err, values) => {
//       if (!err) {
//         // this.props.showAuthLoader();
//         // await this.props.userSignIn(values);
//         this.onSignInUser(values);
//       }
//     });
//   };

//   onSignInUser = (values)=> {
//     const {signInAuth, form} = this.props;
//     const oauthData = {
//       client_id: CLIENT_ID,
//       client_secret: CLIENT_SECRET,
//       grant_type: 'password',
//       email: values.email,
//       password: values.password
//   };
//   signInAuth(oauthData).then((response)=> {
//     if (response.body.error_description) {
//       form.resetFields('password');
//       return message.error(response.body.error_description);
//     }
//     this.saveAuthDataToLocalStorage({access_token: response.body.access_token, refresh_token: response.body.refresh_token});
//     }).catch(error=>message.error(error?.body?.message))
//   };

//   saveAuthDataToLocalStorage = async access_keys => {
//     const { history} = this.props;
//     await localStorage.setItem('user', JSON.stringify(access_keys));
//     setTimeout(() => {
//       history.push('/');
//     }, 300);

//   }

//   onUserChoose = e => {
//     const currentUserEmail = e.currentTarget.dataset.name;
//     const currentUser = USERS.find(user => user.mail === currentUserEmail);
//     const values = { email: currentUser.mail, password: currentUser.password };
//     this.onSignInUser(values);
//   };

//   render() {
//     const { getFieldDecorator } = this.props.form;
//     const { showMessage, loader, alertMessage, invitationUser, isUsersSaving } = this.props;
//     const content = (
//       <div>
//         <ul>
//           {USERS.map(user => {
//             return (
//               <li key={user.mail} data-name={user.mail} onClick={this.onUserChoose} style={{ cursor: 'pointer' }}>
//                 <span style={{ color: 'blue' }}>User:</span> {user.userName}
//               </li>
//             );
//           })}
//         </ul>
//       </div>
//     );
// const errorMsg = alertMessage?.data?.response?.data?.message ? alertMessage?.data?.response?.data?.message : alertMessage.toString();

//     return (
//       <div className="gx-app-login-wrap">
//         <div className="gx-app-login-container">
//           <div className="gx-app-login-main-content">
//           <div className={style.logoWrapper}>
//               <img src={logo} alt="Locolyser logo" className={style.logoStyle} />
//               {(isStaging || isLocalhost) && (
//                       <Popover content={content} title="Choose user (for developing tool)" trigger="hover">
//                         <Icon type="question-circle-o" className={style.tooltipStyle} />
//                       </Popover>
//                     )}
//             </div>
//             <div className="gx-app-login-content">
//               <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
//                 {/* <span>
//                   <h2 className="gx-app-login-name">
//                     Sign In{' '}
//                   </h2>
//                 </span> */}

//                 <FormItem>
//                   {getFieldDecorator('email', {
//                     initialValue: invitationUser.items.length ? invitationUser.items[0].email : null,
//                     rules: [
//                       {
//                         required: true,
//                         type: 'email',
//                         message: 'Email is not valid!'
//                       }
//                     ]
//                   })(<Input placeholder="Email" data-testid="email" />)}
//                 </FormItem>
//                 <FormItem>
//                   {getFieldDecorator('password', {
//                     rules: [{ required: true, message: 'Please input your Password!' }]
//                   })(<Input.Password type="password" placeholder="Password" data-testid="password" />)}
//                 </FormItem>
//                 <FormItem>
//                   {/* {getFieldDecorator('remember', {
//                     valuePropName: 'checked',
//                     initialValue: true
//                   })(
//                     <Checkbox>
//                       <IntlMessages id="appModule.rememberMe" />
//                     </Checkbox>
//                   )} */}
//                   <Link to='/forgotPassword'>
//                     <p className={style.forgotPassword}>Forgot password?</p>
//                   </Link>
//                 </FormItem>
//                 <FormItem>
//                   <Button type="primary" disabled = {isUsersSaving} className="gx-mb-0 gx-app-login-button" htmlType="submit" data-testid="submit-button">
//                     <IntlMessages id="app.userAuth.signIn" />
//                   </Button>
//                 </FormItem>
//               </Form>
//             </div>
//             {loader || isUsersSaving ? (
//               <div className="gx-loader-view">
//                 <Spin />
//               </div>
//             ) : null}
//             {showMessage ? message.error(errorMsg) : null}
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// const WrappedNormalSignInForm = Form.create()(SignIn);

// const mapStateToProps = state => {
//   const { loader, alertMessage, showMessage, authUser } = state.auth;
//   return {
//     loader,
//     alertMessage,
//     showMessage,
//     authUser,
//     invitationUser: state.invitation,
//     isUsersSaving: state.authUser.isSignIning,
//   };
// };
// export default connect(
//   mapStateToProps,
//   dispatch => ({
//     ...bindActionCreators(authActions, dispatch),
//     userSignIn,
//     hideMessage,
//     showAuthLoader
//   })
// )(WrappedNormalSignInForm);
