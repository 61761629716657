export default (state = {}, action) => {
  switch (action.type) {
    case '@@resource/DASHBOARD_CHART/FETCH':
      if (action.status === 'resolved') {
        return { status: action.status, updateTime: action.receivedAt, ...action.body };
      }
      return state;
    default:
      return state;
  }
};
