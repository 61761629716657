import axios from 'axios';
import setResourceToken from '../resources/config';
import { API_URL, CLIENT_ID, CLIENT_SECRET } from 'config';
import { history } from 'appRedux/store';

export function saveUserTokens(data) {
  if (data.data.status === 200) {
    const userEmail = data.data.email ? data.data.email : JSON.parse(localStorage.getItem('user') || '{}').email;
    const access_keys = {
      access_token: data.data.access_token,
      refresh_token: data.data.refresh_token,
      email: userEmail
    };
    localStorage.setItem('user', JSON.stringify(access_keys));
    setResourceToken(data.data.access_token);
    return data;
  }

  return Promise.reject(data);
}

let refreshTokenPromise;

export function refreshToken() {
  const refreshKey = JSON.parse(localStorage.getItem('user') || '{}').refresh_token;
  const userEmail = JSON.parse(localStorage.getItem('user') || '{}').email;
  if (!refreshKey) {
    return Promise.reject();
  }
  if (!refreshTokenPromise) {
    refreshTokenPromise = axios
      .post(`${API_URL}v2/oauth2/token`, {
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        grant_type: 'refresh_token',
        refresh_token: refreshKey,
        email: userEmail
      })
      .then(saveUserTokens)
      .then(res => {
        refreshTokenPromise = null;
        return res;
      })
      .catch(errorResponse => {
        localStorage.removeItem('user');
        history.push({
          pathname: '/signin',
          state: { from: history.location }
        });
        return Promise.reject(errorResponse);
      });
  }

  return refreshTokenPromise;
}
