import React, { Component } from 'react';
import { Form, Select, Input, Button, Popconfirm } from 'antd';
import style from './style.module.less';
import classnames from 'classnames';
import DefaultLogo from '../../../../../assets/images/Localyser_Brand_Icon.png';

const children = [];

class EditBrandForm extends Component {
  state = {
    formLayout: 'horizontal',
    loading: false,
    fileSizeError: '',
    currentBrandLogo: '',
    logoFetching: true
  };

  componentDidMount() {
    this.changeFormLayout();
    window.addEventListener('resize', this.changeFormLayout);
  }
  componentDidUpdate() {
    if (window.screen.width < 460 && this.state.formLayout === 'horizontal') {
      this.changeFormLayout();
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.changeFormLayout);
  }
  changeFormLayout = () => {
    if (window.screen.width < 460) {
      this.setState(() => ({
        formLayout: 'vertical'
      }));
    } else {
      this.setState(() => ({
        formLayout: 'horizontal'
      }));
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    const { form, onSaveCompanyBrand, createCompanyBrand, editedCompanyBrand, uploadingLogo, saveBrandLogoAndGoBack } = this.props;
    form.validateFields((err, values) => {
      if (!err && form.isFieldsTouched()) {
        if (editedCompanyBrand) {
          const companyBrand = { brand: editedCompanyBrand.id, ...values };
          onSaveCompanyBrand(companyBrand);
        } else {
          const companyBrand = { ...values };
          createCompanyBrand(companyBrand);
        }
      } else {
        if (uploadingLogo) {
          !form.isFieldsTouched() && saveBrandLogoAndGoBack(editedCompanyBrand.id);
        }
      }
    });
  };
  onUploadFile = e => {
    const maxSize = 5 * 1048576;
    if (e.target.files[0].size > maxSize) {
      this.setState({ fileSizeError: 'Uploaded file is too big. Image size should not exceed 5Mb!' });
    } else {
      if (this.state.fileSizeError) {
        this.setState({ fileSizeError: '' });
      }
      this.props.onUploadFile(e.target.files[0]);
    }
  };
  onBackWithoutSaving = () => {
    const { uploadingLogo, deleteUploadedLogo, onBack } = this.props;
    uploadingLogo && deleteUploadedLogo();
    onBack();
  };

  render() {
    const { formLayout, fileSizeError } = this.state;
    const formItemLayout =
      formLayout === 'horizontal'
        ? {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
          }
        : null;
    const { getFieldDecorator, isFieldsTouched } = this.props.form;
    const { onBack, editedCompanyBrand, uploadingLogo, createSaveBrandError, isSaving } = this.props;
    const states =
      editedCompanyBrand && editedCompanyBrand.states ? editedCompanyBrand.states.map(brand => brand.title).filter(state => state !== 'Other') : [];

    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit} className={style.editBrandForm} labelAlign="left">
        <Form.Item label="Brand name" className={style.formItem}>
          {getFieldDecorator('title', {
            initialValue: editedCompanyBrand ? editedCompanyBrand.title : null
          })(<Input type="text" />)}
        </Form.Item>
        <Form.Item label="Region" className={style.formItem}>
          {getFieldDecorator('states', {
            initialValue: editedCompanyBrand ? states : []
          })(
            <Select mode="tags" tokenSeparators={[',']}>
              {children}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Logo" className={classnames(style.logoItem, style.formItem)}>
          <input style={{ display: 'none' }} type="file" accept="image/*" onChange={this.onUploadFile} ref={inputRef => (this.inputRef = inputRef)} />
          <Button className={style.uploadButton} icon="download" onClick={() => this.inputRef.click()}>
            Upload logo
          </Button>
          {(uploadingLogo || editedCompanyBrand) && (
            <img
              src={uploadingLogo ? URL.createObjectURL(uploadingLogo) : (editedCompanyBrand.photoUrl ? `${editedCompanyBrand.photoUrl}?rand=${new Date().getTime()}` : DefaultLogo)}
              alt="Brand logo"
              className={style.logoStyle}
              width="150px"
              height="auto"
            />
          )}
        </Form.Item>

        {fileSizeError && <p className={style.errorMessage}> {fileSizeError}</p>}
        {createSaveBrandError && <p className={style.errorMessage}> {createSaveBrandError}</p>}
        <Form.Item>
          <div className={style.editBrandButtonsWrapper}>
            <Button className={style.saveBrandButton} type="primary" htmlType="submit" loading={isSaving}>
              Save
            </Button>
            {isFieldsTouched() || uploadingLogo ? (
              <Popconfirm
                title={"Changes aren't saved. Are you sure you want to go back?"}
                onConfirm={this.onBackWithoutSaving}
                okText="Yes"
                cancelText="No"
              >
                <span className={style.goBackButton}>Back</span>
              </Popconfirm>
            ) : (
              <span className={style.goBackButton} onClick={onBack}>
                Back
              </span>
            )}
          </div>
        </Form.Item>
      </Form>
    );
  }
}
const WrappedEditBrandForm = Form.create({ name: 'editBrand_form' })(EditBrandForm);

export default WrappedEditBrandForm;
