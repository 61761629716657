import React from 'react';
import { Button, Form, Input, Spin } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as forgotPasswordEmailActions } from 'appRedux/resources/forgotPassword/forgotPassword';
import { notification } from 'antd';
import IntlMessages from 'util/IntlMessages';
import logo from 'assets/images/Localyser_Logo_Primary.png';
import style from './style.module.less';
import { FORGOT_PASSWORD_URL } from '../../config'


const FormItem = Form.Item;

class ForgotPassword extends React.Component {

    onSendEmail = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const createBody = {
                    email: values.email,
                    url: FORGOT_PASSWORD_URL
                };
                const actionOpts = { query: null };
                this.props.createForgotPasswordMail(createBody, actionOpts).then(() => notification.success({
                    message: 'Please check your inbox, the recovery password link has been sent.',
                })).catch(error => {
                    return notification.error({
                        message: 'No user is found with this email, please check again.',
                    })
                })
            }
        });
    };


    render() {
        const { getFieldDecorator } = this.props.form;
        const { loader } = this.props;

        return (
            <div className="gx-app-login-wrap">
                <div className="gx-app-login-container">
                    <div className="gx-app-login-main-content">
                        <div className="gx-app-login-content">
                            <div className={style.logoWrapper}>
                                <img src={logo} alt="Locolyser logo" className={style.logoStyle} />
                            </div>
                            <Form onSubmit={this.onSendEmail} className="gx-signin-form gx-form-row0">
                                <span>
                                    <h2 className={style.forgotPasswordTitle}>
                                        Forgot Your Password?
                                    </h2>
                                    <p className={style.description}>
                                        Don't worry, recovering your password is easy. Simply enter the email address you have registered with Localyser.
                                    </p>
                                </span>

                                <FormItem>
                                    {getFieldDecorator('email', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                type: 'email',
                                                message: 'Email is not valid!'
                                            }
                                        ]
                                    })(<Input placeholder="E-mail Address" />)}
                                </FormItem>
                                <FormItem>
                                    <Button type="primary" className="gx-mb-0 gx-app-login-button" htmlType="submit">
                                        <IntlMessages id="app.userAuth.send" />
                                    </Button>
                                </FormItem>
                            </Form>
                        </div>
                        {loader ? (
                            <div className="gx-loader-view">
                                <Spin />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

const WrappedNormalLoginForm = Form.create()(ForgotPassword);

const mapStateToProps = state => {
    const { loader, } = state.auth;
    return {
        loader,
    };
};

export default connect(
    mapStateToProps,
    dispatch => ({
        ...bindActionCreators(forgotPasswordEmailActions, dispatch),
    })
)(WrappedNormalLoginForm);
