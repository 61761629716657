import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as currentUserActions } from 'appRedux/resources/currentUser/currentUserResources';
import { userSignOut } from 'appRedux/actions/Auth';
import { Link } from 'react-router-dom';

import { Dropdown, Menu, Icon } from 'antd';

import style from './userProfile.module.less';

const PDF_EXPORT_MODE = () => !!window.REDUX_DATA;

class UserProfile extends Component {
  componentDidMount() {
    if (!PDF_EXPORT_MODE()) {
      if (!this.props.currentUser) {
        // this.props.fetchCurrentUsers();
      }
    }
  }

  signOutFromPlatform = () => {
    this.props.userSignOut();
  };
  render() {
    const { classname, currentUser } = this.props;
    const userName =
      currentUser &&
      (currentUser.firstName && currentUser.firstName === currentUser.lastName
        ? `${currentUser.firstName}`
        : currentUser.firstName || currentUser.lastName
        ? `${currentUser.firstName ? currentUser.firstName : ''} ${currentUser.lastName ? currentUser.lastName : ''}`
        : 'New user');

    const menu = (
      <Menu>
        <Menu.Item key="0">
          <Link to="/user" className={style.userProfileMenuItem}>
            <Icon type="user" />
            <span>User Settings</span>
          </Link>
        </Menu.Item>
        <Menu.Divider />
        {currentUser.role === 'Master Admin' ? (
          <Menu.Item key="1">
            <Link to="/accounts" className={style.userProfileMenuItem}>
              <i className="icon icon-setting" />
              <span>Accounts Settings</span>
            </Link>
          </Menu.Item>
        ) : null}
        <Menu.Divider />
        <Menu.Item key="2" className={style.userProfileMenuItem} onClick={() => this.signOutFromPlatform()}>
          <i className="icon icon-signin" />
          <span>Sign out</span>
        </Menu.Item>
      </Menu>
    );

    return (
      <>
        <div className={classname}>
          <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
            <Dropdown overlay={menu} trigger={['click']} className={style.userAvatarAndName}>
              <p className="gx-avatar-name">
                <span>{userName}</span>
                <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
              </p>
            </Dropdown>
            {/* <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
              <div className={style.userAvatarAndName}>
                <p className="gx-avatar-name">
                  {currentUser && currentUser.firstName && currentUser.lastName ? <span>{userName}</span> : <span>New user</span>}
                  <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
                </p>
              </div>
            </Popover> */}
          </div>
        </div>
      </>
    );
  }
}
const MDTP = dispatch => {
  return {
    ...bindActionCreators(currentUserActions, dispatch),
    userSignOut: function() {
      dispatch(userSignOut());
    }
  };
};
export default connect(
  state => ({
    currentUser: state.team_users.currentUser.items[0],
    isCurrentUserFetching: state.team_users.currentUser.isFetching
  }),
  MDTP
)(UserProfile);
