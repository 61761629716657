import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v2/api/team/:id/invite`;

export const { types, actions, rootReducer } = createResource({
  name: 'inviteUser',
  url,

  actions: {
    create: {
      method: 'POST'
    }
  }
});
