import React from 'react';
import style from './style.module.less';
import classNames from 'classnames';
import EditBrandForm from '../EditBrandForm';
import PropTypes from 'prop-types';

const EditBrandPopUp = ({
  createCompanyBrand,
  editedCompanyBrand,
  onBack,
  onSaveCompanyBrand,
  onUploadFile,
  uploadingLogo,
  saveBrandLogoAndGoBack,
  deleteUploadedLogo,
  createSaveBrandError,
  isSaving
}) => {
  return (
    <div className={style.editBrandWrapper}>
      <div className={style.editBrandOverflow}>
        <div className={style.editBrandHeader}>
          <h2>{editedCompanyBrand ? `Edit ${editedCompanyBrand.title} brand` : 'Add brand'}</h2>
          <i className={classNames(style.exitEditBrandIcon, 'icon-close')} onClick={onBack} />
        </div>
        <EditBrandForm
          createCompanyBrand={createCompanyBrand}
          onSaveCompanyBrand={onSaveCompanyBrand}
          editedCompanyBrand={editedCompanyBrand}
          onBack={onBack}
          deleteUploadedLogo={deleteUploadedLogo}
          onUploadFile={onUploadFile}
          uploadingLogo={uploadingLogo}
          saveBrandLogoAndGoBack={saveBrandLogoAndGoBack}
          createSaveBrandError={createSaveBrandError}
          isSaving={isSaving}
        />
      </div>
    </div>
  );
};
EditBrandPopUp.propTypes = {
  editedCompanyBrand: PropTypes.object,
  onBack: PropTypes.func.isRequired,
  onSaveCompanyBrand: PropTypes.func.isRequired,
  createCompanyBrand: PropTypes.func.isRequired,
  onUploadFile: PropTypes.func.isRequired
};

export default EditBrandPopUp;
