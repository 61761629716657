import { createResource } from 'redux-rest-resource';
import { API_URL } from '../../../config';

const url = `${API_URL}v2/api/report/settings`;

export const { types, actions, rootReducer } = createResource({
  name: 'reportSettings',
  url,
  actions: {
    fetch: {
      method: 'GET',
      transformResponse: res => ({
        res,
        body: res.body.data
      })
    },
    update: {
      method: 'POST'
    },
    trigger: {
      url: `${API_URL}v2/api/report/settings/trigger`,
      method: 'POST'
    }
  }
});
