import { applyMiddleware, compose, createStore } from 'redux';
import reducers from '../reducers/index';
import { routerMiddleware } from 'react-router-redux';
import createHistory from 'history/createBrowserHistory';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas/index';
import ReduxThunk from 'redux-thunk';
import { isPRODUCTION } from 'config';
import Pusher from 'pusher-js';

const history = createHistory();
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const pusher = new Pusher(isPRODUCTION ? 'cd0c6c8bc33c1e379dfa' : '37b75c0a1dca93dba681', {
  cluster: 'us2'
});
const middlewares = [sagaMiddleware, routeMiddleware, ReduxThunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState) {
  const store = isPRODUCTION
    ? createStore(reducers, initialState, applyMiddleware(...middlewares))
    : createStore(reducers, initialState, composeEnhancers(applyMiddleware(...middlewares)));

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers/index', () => {
      const nextRootReducer = require('../reducers/index');
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}

export { history, pusher };
