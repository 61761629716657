import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v3/api/reviews/insights`;

export const { types, actions, rootReducer } = createResource({
  name: 'insightsSecondary',
  plural: 'insightsSecondary',
  url,
  actions: {
    fetch: {
      url: `${API_URL}v3/api/reviews/insights/secondary`,
      method: 'GET'
    }
  }
});
