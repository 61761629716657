import React from 'react';
import { Button, Form, Input, notification, Spin } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { hideMessage, showAuthLoader, userSignUp } from 'appRedux/actions/Auth';
import { actions as invitationActions } from 'appRedux/resources/team_access/invitationResources';
import { message } from 'antd/lib/index';
import CircularProgress from 'components/CircularProgress/index';
import style from './invitation.module.less';
import logo from 'assets/images/Localyser_Logo_Primary.png';

const FormItem = Form.Item;

class Invitation extends React.Component {
  state = {
    confirmDirty: false,
    userAlredyConfirmed: false,
  };
  componentDidMount() {
    if (this.props.match.params.token) {
      const { token } = this.props.match.params;
      this.checkInvitation(token);
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (values.password === values.confirm) {
          this.confirmPassword(values.password);
        }
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value.length < 6) {
      callback('Password length should be not less than 6 characters!');
    }
    else {
      if (value && value !== form.getFieldValue('password')) {
        callback('Two passwords that you enter is inconsistent!');
      } else {
        callback();
      }
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value.length < 6) {
      callback('Password length should be not less than 6 characters!');
    } else {
      if (value && this.state.confirmDirty) {
        form.validateFields(['confirm'], { force: true });
      }
      callback();
    }
  };

  goToSignIn = () => {
    this.props.history.push('/signin');
  };

  checkInvitation = token => {
    const fetchContext = {
      token: token
    };
    const actionOpts = { query: null };
    this.props.fetchInvitations(fetchContext, actionOpts).then(data => {
      if (data.body.message === "User confirmed or it is wrong invitation id")
        this.setState({
          userAlredyConfirmed: true
        }, () => setTimeout(this.goToSignIn, 4000))
    }).catch((error) => {
      notification.error({
        message: 'Error'
      })
    });
  };
  confirmPassword = password => {
    const { token } = this.props.match.params;
    const createBody = {
      token,
      password: password
    };
    const actionOpts = { query: null };
    this.props.createInvitation(createBody, actionOpts).then(() => {
      localStorage.setItem('fromInvitation', "true")
      return this.goToSignIn();
    }).catch(error => {
      notification.error({
        message: 'Confirm password error'
      })
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { showMessage, loader, alertMessage, invitationUser, isFetching } = this.props;
    const { userAlredyConfirmed } = this.state;
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className={style.logoWrapper}>
              <img src={logo} alt="Locolyser logo" className={style.logoStyle} />
            </div>
            {userAlredyConfirmed && <p className={style.userIsConfirmed}> Your account is already  confirmed, you will be redirected to
              <a href='https://app.localyser.com/signin'> https://app.localyser.com </a>
              to login.
              </p>}
            {!userAlredyConfirmed && invitationUser.items.length > 0 &&
              <>
                <div className={style.invitationHeader}>
                  {invitationUser.items.length && invitationUser.items[0].firstName ? (
                    <h2>Hello {invitationUser.items[0].firstName}! Please create a new password.</h2>
                  ) : (
                      <h2>Hello new team user! Please create a new password.</h2>
                    )}
                </div>
                <div className="gx-app-login-content">
                  <Form onSubmit={this.handleSubmit} className="gx-signup-form gx-form-row0">
                    <FormItem>
                      {getFieldDecorator('email', {
                        initialValue: invitationUser.items.length ? invitationUser.items[0].email : null,
                        rules: [
                          {
                            type: 'email'
                          }
                        ]
                      })(<Input placeholder="Email" disabled />)}
                    </FormItem>
                    <FormItem>
                      {getFieldDecorator('password', {
                        rules: [
                          {
                            required: true,
                            message: 'Please input your Password!'
                          },
                          {
                            validator: this.validateToNextPassword
                          }
                        ]
                      })(<Input.Password type="password" placeholder="Password" />)}
                    </FormItem>
                    <FormItem>
                      {getFieldDecorator('confirm', {
                        rules: [
                          {
                            required: true,
                            message: 'Please input your confirm password!'
                          },
                          {
                            validator: this.compareToFirstPassword
                          }
                        ]
                      })(<Input.Password type="password" placeholder="Confirm password" />)}
                    </FormItem>
                    <FormItem>
                      <Button type="primary" className="gx-mb-0" htmlType="submit">
                        Confirm password
                      </Button>
                    </FormItem>
                  </Form>
                </div>
              </>}
            {isFetching && (
              <div className={style.spinnerWrapper}>
                <div className={style.spinnerSignUp}>
                  <Spin />
                </div>
              </div>
            )}
            {loader && (
              <div className="gx-loader-view">
                <CircularProgress />
              </div>
            )}
            {showMessage && message.error(alertMessage)}
          </div>
        </div>
      </div>
    );
  }
}

const WrappedSignUpForm = Form.create()(Invitation);

const mapStateToProps = state => {
  const { loader, alertMessage, showMessage, authUser } = state.auth;
  return {
    loader,
    alertMessage,
    showMessage,
    authUser,
    invitationUser: state.invitation,
    isFetching: state.invitation.isFetching
  };
};

export default connect(
  mapStateToProps,
  dispatch => ({
    ...bindActionCreators(invitationActions, dispatch),
    userSignUp,
    hideMessage,
    showAuthLoader
  })
)(WrappedSignUpForm);
