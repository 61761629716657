import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v2/api/admin/googleOAuth`;

export const { types, actions, rootReducer } = createResource({
    name: 'deleteOtherAccount',
    url,

    actions: {
        delete: {
            method: 'DELETE'
        },
    }
});