import { createResource } from 'redux-rest-resource';
import { API_URL, COMPANY_TYPES } from 'config';

const url = `${API_URL}v2/api/me`;

export const { types, actions, rootReducer } = createResource({
  name: 'currentUser',
  url,
  actions: {
    fetch: {
      method: 'GET',
      transformResponse: res => {
        const currentUserCompany = res.body.data?.companyType && COMPANY_TYPES.find(company => company.company_type === res.body.data.companyType);
        const locationsTitle = currentUserCompany ? (currentUserCompany.hasLocation ? 'Location' : 'Product') : 'Unit';
        return {
          res,
          body: { ...res.body.data, locationsTitle }
        };
      }
    },
    //when owner log in as company we don't update fetchCurrentUsers query, but we need to have current company settings
    //as features, package, companyType etc, that is why we found company settings and update current users settings
    updateSettings: {
      method: 'GET',
      url: `${API_URL}v2/api/admin/companies/:id`,
      reduce: (state, action) => {
        if (action.status === 'resolved') {
          const currentUserCompany = action.body?.data?.type && COMPANY_TYPES.find(company => company.company_type === action.body.data.type);
          const locationsTitle = currentUserCompany ? (currentUserCompany.hasLocation ? 'Location' : 'Product') : 'Unit';
          const currentUserSettings = { ...state.items[0] };
          currentUserSettings.companyType = action.body?.data?.type;
          currentUserSettings.locationsTitle = locationsTitle;
          currentUserSettings.features = action.body?.data?.features;
          currentUserSettings.package = action.body?.data?.package;
          currentUserSettings.clientSettings = action.body?.data?.clientSettings
            ? action.body?.data?.clientSettings
            : currentUserSettings.clientSettings;

          const updatedItems = [currentUserSettings];
          return { ...state, items: updatedItems };
        }

        return { ...state };
      }
    }
  }
});
