import { createResource } from 'redux-rest-resource';
import { API_URL } from '../../config';

export const { types, actions, rootReducer } = createResource({
  name: 'tagsMessage',
  actions: {
    fetch: {
      method: 'POST',
      url: `${API_URL}v2/api/tagsmessage/get`,
      transformResponse: res => {
        return {
          res,
          body: res.body
        };
      }
    },
    create: {
      method: 'POST',
      url: `${API_URL}v2/api/tagsmessage/create`,
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return state;
        }
        if (action.body !== '') {
          const tags = [...state.items, action.body];
          return { items: tags };
        }
        return { items: state.items };
      }
    },
    deleteTagsMessage: {
      method: 'DELETE',
      url: `${API_URL}v2/api/tagsmessage/delete`
    }
  }
});
