import React, { Component } from 'react';
import { Table, Icon, Popconfirm } from 'antd';
import style from './style.module.less';
import IntlMessages from 'util/IntlMessages';
import PropTypes from 'prop-types';
import Buttons from '../Butons';

class CompanyBrands extends Component {
  static propTypes = {
    editBrandsSettings: PropTypes.func.isRequired,
    // onBack: PropTypes.func.isRequired,
    // onNext: PropTypes.func.isRequired,
    companyBrands: PropTypes.array,
    isCompanyBrandsFetching: PropTypes.bool,
    deleteCompanyBrand: PropTypes.func.isRequired
  };

  findStates = states => {
    try{
      const brandStates = [];
      states && states.forEach(state => brandStates.push(state.title));
      return states ? brandStates.filter(state => state !== 'Other').join(', ') : '';
    }catch(ex){
      return '';
    }
  };
  render() {
    const { editBrandsSettings, onBack, onNext, companyBrands, isCompanyBrandsFetching, deleteCompanyBrand } = this.props;
    const columns = [
      {
        title: 'Brand name',
        dataIndex: 'title'
        // sorter: true
      },
      {
        title: 'Region',
        dataIndex: 'states',
        // sorter: true
        render: states => this.findStates(states)
      },
      {
        title: 'Locations',
        dataIndex: 'locations',
        render: locations => (locations ? locations.length : 0)
        // sorter: true
      },
      {
        title: 'Edit',
        dataIndex: 'key',
        render: key => (
          <Icon data-name={key} type="edit" className={style.editCompanyIcon} onClick={e => editBrandsSettings(e)} theme="filled" twoToneColor />
        )
      },
      {
        title: 'Remove',
        dataIndex: 'id',
        render: id => (
          <Popconfirm
            title={<IntlMessages id="admin_panel.companies.brandDelete" />}
            onConfirm={() => deleteCompanyBrand(id)}
            okText="Yes"
            cancelText="No"
          >
            <Icon id={id} className={style.deleteCompanyIcon} type="close" />
          </Popconfirm>
        )
      }
    ];

    return (
      <>
        <Table
          columns={columns}
          dataSource={companyBrands}
          pagination={false}
          className={style.brandsTabel}
          scroll={{ x: 'fit-content' }}
          indentSize={5}
          loading={isCompanyBrandsFetching}
        />
        <Buttons onBack={onBack} onNextToCompanies={onNext} nextButtonTitle='Next' />
      </>
    );
  }
}

export default CompanyBrands;
