import React, { useState } from 'react';
import { Form, Select, Popconfirm, Button, Input } from 'antd';
import style from './style.module.less';
import { PARTNERS_PACKAGES } from 'config';
import { useEffect } from 'react';

const { Option } = Select;

const PartnerPackage = ({ onBack, form, editedPartner, updateCreatedPartner, isValuesChanged, nextButtonStyle, buttonDisabled }) => {
  const { getFieldDecorator, getFieldsValue, getFieldValue } = form;
  const [packageCredits, setPackageCredits] = useState('');
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
    }
  };
  useEffect(() => {
    if (editedPartner && packageCredits === '' && editedPartner.package && editedPartner.settings) {
      try {
        JSON.parse(editedPartner.settings);
      } catch (e) {
        const packageSettings = editedPartner.settings;
        return packageSettings && setPackageCredits(packageSettings.packageCredits);
      }
      const packageSettings = JSON.parse(editedPartner.settings);
      packageSettings && setPackageCredits(packageSettings.packageCredits);
    }
  }, [packageCredits, editedPartner]);

  const onUpdatePartnerInfo = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        updateCreatedPartner(values, 'apiKey');
      }
    });
  };
  const handleSelectChange = value => {
    const currentPackageSettings = PARTNERS_PACKAGES.find(currentPackage => currentPackage.package === value);
    setPackageCredits(currentPackageSettings?.credits);
  };

  const isChangesSaved = () => {
    const currentValues = getFieldsValue(['package']);
    return isValuesChanged({ ...currentValues });
  };

  return (
    <Form {...formItemLayout} onSubmit={onUpdatePartnerInfo} hideRequiredMark labelAlign="left" className={style.companyForm}>
      <div className={style.selectMenuWrapper} id="type_id">
        <Form.Item className={style.formItem} label="Partner package">
          {getFieldDecorator('package', {
            initialValue: editedPartner ? editedPartner.package : null
          })(
            <Select placeholder="Partner" onChange={handleSelectChange} getPopupContainer={() => document.getElementById('type_id')}>
              <Option className={style.defaultValue} value={null}>
                ...
              </Option>
              {PARTNERS_PACKAGES.map(partnerPackage => (
                <Option key={partnerPackage.package} value={partnerPackage.package} className={style.typeSelect}>
                  {partnerPackage.package}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </div>
      <Form.Item className={style.formItem} label="Credits">
        {getFieldDecorator('settings.packageCredits', {
          rules: [{ required: getFieldValue('package') === 'Custom' ? true : false, message: 'Please input package credits!' }],
          initialValue: packageCredits
        })(<Input placeholder="credits" type="number" disabled={getFieldValue('package') !== 'Custom'} />)}
      </Form.Item>

      <Form.Item>
        <div className={style.buttonsWrapper}>
          {isChangesSaved() ? (
            <Popconfirm
              title={
                editedPartner
                  ? "Changes aren't saved. Are you sure you want to go back?"
                  : "Company settings aren't saved. Are your sure you want to go back?"
              }
              onConfirm={onBack}
              okText="Yes"
              cancelText="No"
            >
              <span className={style.goBackButton}>Back</span>
            </Popconfirm>
          ) : (
            <span className={style.goBackButton} onClick={onBack}>
              Back
            </span>
          )}
          <Button type="primary" className={nextButtonStyle} htmlType="submit" disabled={buttonDisabled}>
            Next
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

const WrappedPartnerInformation = Form.create({ name: 'partner_package' })(PartnerPackage);

export default WrappedPartnerInformation;
