import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v2/api/admin/autoresponse/settings`;

export const { types, actions, rootReducer } = createResource({
    name: 'autoResponseSettings',
    url,
    actions: {
        fetch: {
            method: 'GET',
            transformResponse: res => {

                return ({
                    res,
                    body: res.body,
                })
            }
        },
        change: {
            method: 'POST'
        },
    }
});