import React from 'react';
import style from '../../../Settings/Locations/settings/Menu/style.module.less';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const findMenuItem = (activeMenuItem, currentItem) => {
  const menuItem = activeMenuItem.find(item => item.title === currentItem);
  return menuItem.value;
};

const Menu = ({ activeMenuItem, classname, switchMenuSettings, currentuserRole }) => {
  return (
    <ul className={classname}>
      <li
        data-name="information"
        onClick={switchMenuSettings}
        className={classNames(style.menuItem, findMenuItem(activeMenuItem, 'information') && style.selected)}
      >
        Company information
      </li>
      <li
        data-name="sources"
        onClick={switchMenuSettings}
        className={classNames(style.menuItem, findMenuItem(activeMenuItem, 'sources') && style.selected)}
      >
        Sources
      </li>
      <li
        data-name="brands"
        onClick={switchMenuSettings}
        className={classNames(style.menuItem, findMenuItem(activeMenuItem, 'brands') && style.selected)}
      >
        Brands
      </li>
      <li
        data-name="packages"
        onClick={switchMenuSettings}
        className={classNames(style.menuItem, findMenuItem(activeMenuItem, 'packages') && style.selected)}
      >
        Packages
      </li>
      {currentuserRole === 'Partner' && <li
        data-name="locations"
        onClick={switchMenuSettings}
        className={classNames(style.menuItem, findMenuItem(activeMenuItem, 'locations') && style.selected)}
      >
        Locations
      </li>}
      <li
        data-name="apikey"
        onClick={switchMenuSettings}
        className={classNames(style.menuItem, findMenuItem(activeMenuItem, 'apikey') && style.selected)}
      >
        API Key
      </li>

    </ul>
  );
};
Menu.propTypes = {
  activeMenuItem: PropTypes.array,
  classname: PropTypes.string
};

export default Menu;
