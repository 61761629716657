import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v2/api/statistics/locations/performing`;

export const { types, actions, rootReducer } = createResource({
  name: 'lowPerformingLocations',
  url,
  actions: {
    get: {
      method: 'GET',
      transformResponse: res => ({
        res,
        body: res.body.data
      })
    }
  }
});
