export default (state = {}, action) => {
  switch (action.type) {
    case '@@resource/LANDING_PAGE_LOCATION/FETCH':
      if (action.status === 'resolved') {
        return action.paginationLandingPageLocation;
      }
      return state;
    default:
      return state;
  }
};
