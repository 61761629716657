import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as companiesActions } from 'appRedux/resources/companies/companiesResources';
import { actions as companyBrandActions } from 'appRedux/resources/companies/companyBrandResources';
import { actions as packagesActions } from 'appRedux/resources/companies/packages';

import { sortResources } from 'helpers/helpers';
import { API_URL } from 'config';
import { refreshToken } from 'appRedux/resources/changeUserTokens';

import { notification, Spin } from 'antd';
import Menu from '../Menu/Menu';
import CompanyInformation from './CompanyInformation';
import CompanySources from './CompanySources';
import CompanyBrands from './CompanyBrands';
import EditBrandPopUp from './EditBrandPopUp';
import CompanySettingsHeader from './CompanySettingsHeader';
import Packages from './Packages';
import ApiKey from './CompanyApiKey';
import Locations from './Locations';

import style from './style.module.less';

const PDF_EXPORT_MODE = () => !!window.REDUX_DATA;

class CompanySettings extends Component {
  state = {
    activeMenuItem: [
      { title: 'information', value: true },
      { title: 'sources', value: false },
      { title: 'packages', value: false },
      { title: 'locations', value: false },
      { title: 'brands', value: false },
      { title: 'apikey', value: false }
    ],
    checkedSources: [],
    checkedCompetitorsSources: [],
    editedCompanyBrand: null,
    showAddEditBrand: false,
    editedCompany: '',
    // period: null,
    companyInformationChange: null,
    uploadingLogo: null,
    createSaveBrandError: '',
    isCompany: false
  };
  componentDidMount() {
    if (!PDF_EXPORT_MODE()) {
      this.fetchCurrentCompanySetting();
      this.fetchCompanyBrands();
      this.props.fetchPackages();
    }
  }

  fetchCurrentCompanySetting = () => {
    if (this.props.match.params && this.props.match.params.id) {
      const { match, fetchCompanys } = this.props;
      const editedCompanyId = match.params.id;
      const fetchContext = {
        id: editedCompanyId
      };
      const actionOpts = { query: null };
      fetchCompanys(fetchContext, actionOpts)
        .then(response => {
          return this.recordEditedCompany(response.res.body.data);
        })
        .then(() =>
          fetchCompanys(
            {},
            {
              query: { direction: 'desc' }
            }
          )
        );
    }
  };

  checkEditedCompany = () => {
    const { match, companies } = this.props;
    if (match.params && match.params.id && companies) {
      const editedCompanyId = match.params.id;
      const editedCompany = companies.find(company => company.id === +editedCompanyId);
      editedCompany && this.recordEditedCompany(editedCompany);
    }
  };
  recordEditedCompany = editedCompany => {
    editedCompany &&
      this.setState({
        editedCompany,
        checkedSources: editedCompany.sources,
        checkedCompetitorsSources: editedCompany.competitorsSources,
        companyInformationChange: null
      });

    return editedCompany;
  };

  toggleSettings = currentItem => {
    const activeMenuItem = this.state.activeMenuItem.map(item => {
      if (item.title === currentItem) {
        return { ...item, value: true };
      } else {
        return { ...item, value: false };
      }
    });
    this.setState({
      activeMenuItem
    });
  };
  switchMenuSettings = e => {
    const currentItem = e.currentTarget.dataset.name;
    const activeMenuItem = this.state.activeMenuItem.map(item => {
      if (item.title === currentItem) {
        return { ...item, value: true };
      } else {
        return { ...item, value: false };
      }
    });
    if (this.props.companyBrands.length > 0) {
      this.isCompany = true;
    } else {
      this.isCompany = false;
    }
    this.setState({
      activeMenuItem
    });
  };
  onGoToCompanyInformation = () => {
    this.toggleSettings('information');
  };
  onGoToCompanyBrands = () => {
    this.toggleSettings('brands');
    if (this.props.companyBrands.length > 0) {
      this.isCompany = true;
    } else {
      this.isCompany = false;
    }
  };

  onGoToCompanySources = () => {
    this.toggleSettings('sources');
  };
  onGoToCompanyPackages = () => {
    this.toggleSettings('packages');
  };
  hideCompanySettings = () => {
    this.props.history.push(`/admin_panel/companies`);
  };
  createCompany = values => {
    if (!this.props.isCreating) {
      this.props
        .createCompany(values)
        .then(data => this.recordEditedCompany(data.body.data))
        .then(() => this.openNotification('success', 'Сompany successfully created'))
        .then(() => this.toggleSettings('sources'))
        .catch(error => {
          const errorMsg = error.body?.error_description ? error.body?.error_description : error.body ? error.body : 'Error';
          this.openNotification('error', errorMsg);
        });
    }
  };
  updateCompanySources = () => {
    const { checkedSources, editedCompany, checkedCompetitorsSources } = this.state;
    const competitorsSourcesWithoutturnOffResources = checkedCompetitorsSources.filter(competSource => checkedSources.includes(competSource));
    const updatedCompany = { id: editedCompany.id, sources: checkedSources, competitorsSources: competitorsSourcesWithoutturnOffResources };
    const currentSources = checkedSources.length ? sortResources(checkedSources).join() : null;
    const companySources = editedCompany.sources.length ? sortResources(editedCompany.sources).join() : editedCompany.sources;

    const currentCompetitorsSources = checkedCompetitorsSources.length ? sortResources(checkedCompetitorsSources).join() : null;
    const companyCompetitorsSources = editedCompany.competitorsSources.length
      ? sortResources(editedCompany.competitorsSources).join()
      : editedCompany.competitorsSources;
    if (currentSources !== companySources || currentCompetitorsSources !== companyCompetitorsSources) {
      this.props
        .updateCompany(updatedCompany, { query: null })
        .then(data => this.recordEditedCompany(data.body))
        .then(() => this.openNotification('success', 'Сompany sources successfully updated.'))
        .then(() => this.toggleSettings('brands'))
        .catch(error => error.body?.error_description && this.openNotification('error', error.body.error_description));
    }
  };

  updateCompanyInformation = editedCompany => {
    this.props
      .updateCompany(editedCompany, {})
      .then(data => this.recordEditedCompany(data.body))
      .then(() => this.openNotification('success', 'Сompany successfully updated'))
      .then(() => this.toggleSettings('sources'))
      .catch(error => {
        const errorMsg = error.body?.error_description ? error.body?.error_description : error.body ? error.body : 'Error';
        this.openNotification('error', errorMsg);
      });
  };
  updateCompanyLocationsLimit = editedCompany => {
    this.props
      .updateCompany(editedCompany, {})
      .then(data => this.recordEditedCompany(data.body))
      .then(() => this.openNotification('success', 'Сompany successfully updated'))
      .then(() => this.toggleSettings('apikey'))
      .catch(error => {
        error.body && error.body.error_description && this.openNotification('error', error.body.error_description);
      });
  };

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  showMenuItem = currentItem => {
    const { activeMenuItem } = this.state;
    const menuItem = activeMenuItem.find(item => item.title === currentItem);
    return menuItem.value;
  };
  onSourceChange = checkedValues => {
    this.setState({
      checkedSources: checkedValues
    });
  };
  onCompetitoeSourceChange = checkedValues => {
    if (checkedValues.length && checkedValues.length > 4) {
      this.openNotification('error', "You can't select more than 4 sources for competitors.");
    } else {
      this.setState({
        checkedCompetitorsSources: checkedValues
      });
    }
  };
  fetchCompanyBrands = () => {
    const { match } = this.props;
    if (match.params.id || this.state.editedCompany.id) {
      const companyId = match.params.id ? match.params.id : this.state.editedCompany.id ? `${this.state.editedCompany.id}` : null;
      this.props.fetchCompanyBrands({ id: companyId });
    } else this.props.clearCompanyBrand();
  };
  switchAddEditBrand = () => {
    if (this.state.createSaveBrandError) {
      this.setState(prev => ({
        showAddEditBrand: !prev.showAddEditBrand,
        createSaveBrandError: 'test'
      }));
    } else {
      this.setState(prev => ({
        showAddEditBrand: !prev.showAddEditBrand
      }));
    }
  };

  editBrandSettings = e => {
    const editedBrandId = e.currentTarget.dataset.name;
    const { companyBrands } = this.props;
    const editedCompanyBrand = companyBrands.find(companyBrand => companyBrand.id === +editedBrandId);
    console.log(editedCompanyBrand);
    if (this.state.createSaveBrandError) {
      this.setState({
        editedCompanyBrand,
        showAddEditBrand: true,
        createSaveBrandError: ''
      });
    } else {
      this.setState({
        editedCompanyBrand,
        showAddEditBrand: true
      });
    }
  };
  onSaveCompanyBrand = editedCompanyBrandValues => {
    const editedCompanyBrand = { id: this.state.editedCompany.id, ...editedCompanyBrandValues };
    this.props
      .updateCompanyBrand(editedCompanyBrand)
      .then(response => {
        if (this.state.uploadingLogo) {
          const id = this.state.editedCompanyBrand.id;
          return this.saveBrandLogo(id);
        }
        return response;
      })
      .then(() => this.fetchCompanyBrands())
      .then(() => this.onBackEditBrand())
      .catch(error => this.setState({ createSaveBrandError: error.body.error_description }));
  };
  deleteCompanyBrand = brandId => {
    const deleteBrand = {
      id: this.state.editedCompany.id,
      brand: brandId
    };
    this.props
      .deleteCompanyBrand(deleteBrand)
      .then(() => this.fetchCompanyBrands())
      .catch(error => {
        const errorMessage = error.body?.error_description ? error.body?.error_description : 'Error';
        return notification.error({
          message: errorMessage
        });
      });
  };
  createCompanyBrand = newCompanyBrandValues => {
    const editedCompanyBrand = { id: this.state.editedCompany.id, ...newCompanyBrandValues };

    this.props
      .createCompanyBrand(editedCompanyBrand)
      .then(response => {
        if (this.state.uploadingLogo) {
          const id = response.body.id;

          return this.saveBrandLogo(id);
        } else return response;
      })
      .then(() => this.fetchCompanyBrands())
      .then(() => this.onBackEditBrand())
      .catch(error => this.setState({ createSaveBrandError: error.body.error_description }));
  };

  onBackEditBrand = () => {
    this.setState({
      editedCompanyBrand: null,
      showAddEditBrand: false
    });
  };
  recordInformationChangeValues = companyInformationChange => {
    this.setState({
      companyInformationChange
    });
  };
  saveBrandLogo = id => {
    refreshToken()
      .then(() => {
        const fd = new FormData();
        const access_token = JSON.parse(localStorage.getItem('user') || '{}').access_token;
        fd.append('file', this.state.uploadingLogo, 'brandLogo');
        return axios.post(`${API_URL}v2/api/brands/logo?access_token=${access_token}&brand=${id}`, fd);
      })
      .then(() => {
        this.setState({ uploadingLogo: '' });
        this.fetchCompanyBrands();
      });
  };

  saveBrandLogoAndGoBack = id => {
    this.saveBrandLogo(id);
    this.onBackEditBrand();
  };
  onUploadFile = file => {
    this.setState({
      uploadingLogo: file
    });
  };
  deleteUploadedLogo = () => {
    this.setState({ uploadingLogo: '' });
  };
  updatePackage = (packageName, updatedfeatchers = null) => {
    const { editedCompany } = this.state;
    let newPackage = {
      client: editedCompany.id,
      package: packageName,
      packageChangeDate:
        packageName === 'Free trial'
          ? moment()
              .add(30, 'days')
              .format('YYYY/MM/DD')
          : moment().format('YYYY/MM/DD')
    };
    if (updatedfeatchers) {
      newPackage.features = updatedfeatchers;
    }
    this.props
      .updatePackages(newPackage)
      .then(() => this.props.fetchCompanys({ id: editedCompany.id }, { query: null }))
      .then(response => this.recordEditedCompany(response.res.body.data));
  };
  onPackageChangeDate = (date, dateString) => {
    const { editedCompany } = this.state;
    const packageChangeDate = dateString.split('/').join('-');
    let updatedPackage = {
      client: editedCompany.id,
      package: editedCompany.package,
      packageChangeDate
    };
    this.props
      .updatePackages(updatedPackage)
      .then(() => this.props.fetchCompanys({ id: editedCompany.id }, { query: null }))
      .then(response => this.recordEditedCompany(response.res.body.data));
  };

  render() {
    const {
      activeMenuItem,
      editedCompany,
      checkedSources,
      checkedCompetitorsSources,
      editedCompanyBrand,
      showAddEditBrand,
      uploadingLogo,
      createSaveBrandError
    } = this.state;

    const { companyBrands, companyBrandsWithKey, isCompanyBrandsFetching, packages, match, isCompaniesFetching, currentUser } = this.props;
    return (
      <div className={style.companySettingsMain}>
        <CompanySettingsHeader
          classname={style.companySettingsHeader}
          editedCompany={editedCompany}
          showMenuItem={this.showMenuItem}
          onClick={this.switchAddEditBrand}
          isCompanyBrands={this.isCompany}
        />
        <Menu
          activeMenuItem={activeMenuItem}
          switchMenuSettings={this.switchMenuSettings}
          classname={style.adminPanelMenu}
          currentuserRole={currentUser && currentUser.role}
        />
        <CompanyInformation
          createCompany={this.createCompany}
          updateCompanyInformation={this.updateCompanyInformation}
          onBack={this.hideCompanySettings}
          onNext={this.toggleSettings}
          editedCompany={editedCompany}
          updateComanyInformation={this.state.updateComanyInformation}
          recordInformationChangeValues={this.recordInformationChangeValues}
          visible={this.showMenuItem('information')}
        />
        {(this.props.isCreating || this.props.isUpdating) && <Spin className={style.spinner} />}

        {this.showMenuItem('sources') && (
          <CompanySources
            defaultValue={checkedSources}
            checkedCompetitorsSources={checkedCompetitorsSources}
            onSourceChange={this.onSourceChange}
            onBack={this.onGoToCompanyInformation}
            onNext={this.onGoToCompanyBrands}
            updateSources={this.updateCompanySources}
            onCompetitoeSourceChange={this.onCompetitoeSourceChange}
            editedCompany={editedCompany}
          />
        )}
        {this.showMenuItem('brands') && (
          <CompanyBrands
            companyBrands={companyBrands && (match.params.id || editedCompany.id) ? companyBrandsWithKey : null}
            onBack={this.onGoToCompanySources}
            onNext={this.onGoToCompanyPackages}
            editBrandsSettings={this.editBrandSettings}
            deleteCompanyBrand={this.deleteCompanyBrand}
            isCompanyBrandsFetching={isCompanyBrandsFetching}
          />
        )}
        {this.showMenuItem('packages') && packages.length > 0 && (
          <Packages
            allPackages={packages}
            currentPackage={editedCompany && editedCompany.package}
            currentFeatures={editedCompany && editedCompany.features}
            packageChangeDate={editedCompany && editedCompany.packageChangeDate}
            period={editedCompany && editedCompany.period}
            onBack={this.onGoToCompanyBrands}
            onNext={() => this.toggleSettings(currentUser && currentUser.role === 'Partner' ? 'locations' : 'apikey')}
            updatePackage={this.updatePackage}
            onPackageChangeDate={this.onPackageChangeDate}
            isCompaniesFetching={isCompaniesFetching}
            currentuserRole={currentUser && currentUser.role}
          />
        )}
        {this.showMenuItem('locations') && (
          <Locations
            onBack={this.onGoToCompanyPackages}
            onNext={() => this.toggleSettings('apikey')}
            editedCompany={editedCompany}
            updateCompanyLocationsLimit={this.updateCompanyLocationsLimit}
            visible={this.showMenuItem('locations')}
          />
        )}
        {this.showMenuItem('apikey') && (
          <ApiKey
            apiKey={editedCompany && editedCompany.accessToken}
            onBack={() => this.toggleSettings(currentUser && currentUser.role === 'Partner' ? 'locations' : 'packages')}
            onNext={this.hideCompanySettings}
          />
        )}
        {showAddEditBrand && (
          <EditBrandPopUp
            createCompanyBrand={this.createCompanyBrand}
            onSaveCompanyBrand={this.onSaveCompanyBrand}
            editedCompanyBrand={editedCompanyBrand}
            onBack={this.onBackEditBrand}
            onUploadFile={this.onUploadFile}
            uploadingLogo={uploadingLogo}
            saveBrandLogoAndGoBack={this.saveBrandLogoAndGoBack}
            deleteUploadedLogo={this.deleteUploadedLogo}
            createSaveBrandError={createSaveBrandError}
          />
        )}
      </div>
    );
  }
}

const MSTP = state => {
  const companyBrandsWithKey =
    state.admin.companyBrands.items &&
    state.admin.companyBrands.items
      .filter(brand => brand.title !== 'Other')
      .map(companyBrand => {
        return { ...companyBrand, key: companyBrand.id };
      });
  return {
    companies: state.admin.companies.items,
    isCompaniesFetching: state.admin.companies.isFetching,
    packages: state.admin.packages.items,
    companyBrands: state.admin.companyBrands.items,
    companyBrandsWithKey,
    currentUser: state.team_users.currentUser.items[0],
    isCompanyBrandsFetching: state.admin.companyBrands.isFetching,
    isCreating: state.admin.companies.isCreating,
    isUpdating: state.admin.companies.isUpdating
  };
};
export default connect(
  MSTP,
  dispatch => ({
    ...bindActionCreators(companiesActions, dispatch),
    ...bindActionCreators(packagesActions, dispatch),
    ...bindActionCreators(companyBrandActions, dispatch)
  })
)(CompanySettings);
