import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v2/api/password/:token`;

export const { types, actions, rootReducer } = createResource({
    name: 'confirmPassword',
    url,

    actions: {
        create: {
            method: 'POST'
        }
    }
});