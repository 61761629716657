import React from 'react';
import { Button, Form, Input, Spin } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as confirmPasswordActions } from 'appRedux/resources/forgotPassword/confirmPassword';
import { notification } from 'antd';
import IntlMessages from 'util/IntlMessages';
import logo from 'assets/images/Localyser_Logo_Primary.png';
import style from './style.module.less';

const FormItem = Form.Item;

class ConfirmPassword extends React.Component {
    state = {
        confirmDirty: false
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (values.password === values.confirm) {
                    if (values.password.length >= 6) {
                        const { token } = this.props.match.params;
                        const createBody = {
                            token,
                            password: values.password
                        };
                        const actionOpts = { query: null };
                        this.props.createConfirmPassword(createBody, actionOpts).then(() => {
                            localStorage.setItem('fromConfirmPassword', "true")
                            return this.props.history.push('/signin')
                        }
                        ).catch(error => {
                            return notification.error({
                                message: 'No user is found with this email, please check again.',
                            })
                        })
                    } else {
                        return notification.error({
                            message: 'Password should be at least 6 characters.',
                        })
                    }

                }
            }
        });
    };

    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { loader } = this.props;

        return (
            <div className="gx-app-login-wrap">
                <div className="gx-app-login-container">
                    <div className="gx-app-login-main-content">
                        <div className="gx-app-login-content">
                            <div className={style.logoWrapper}>
                                <img src={logo} alt="Locolyser logo" className={style.logoStyle} />
                            </div>
                            <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
                                <span>
                                    <h2 className={style.forgotPasswordTitle}>
                                        Recovery password.
                                    </h2>
                                </span>

                                <FormItem>
                                    {getFieldDecorator('password', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input your Password!'
                                            },
                                            {
                                                validator: this.validateToNextPassword
                                            }
                                        ]
                                    })(<Input.Password placeholder="New Password" />)}
                                </FormItem>
                                <FormItem>
                                    {getFieldDecorator('confirm', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input your confirm password!'
                                            },
                                            {
                                                validator: this.compareToFirstPassword
                                            }
                                        ]
                                    })(<Input.Password placeholder="Confirm Password" />)}
                                </FormItem>
                                <FormItem>
                                    <Button type="primary" className="gx-mb-0 gx-app-login-button" htmlType="submit">
                                        <IntlMessages id="app.userAuth.confirm" />
                                    </Button>
                                </FormItem>
                            </Form>
                        </div>
                        {loader ? (
                            <div className="gx-loader-view">
                                <Spin />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

const WrappedNormalLoginForm = Form.create()(ConfirmPassword);

const mapStateToProps = state => {
    const { loader, } = state.auth;
    return {
        loader,
    };
};

export default connect(
    mapStateToProps,
    dispatch => ({
        ...bindActionCreators(confirmPasswordActions, dispatch),
    })
)(WrappedNormalLoginForm);
