import React from 'react';
import { Form, Select, Input, Popconfirm, DatePicker, Button } from 'antd';
import style from './style.module.less';
import { STATUSES, PARTNERS_TYPE, INVITE_TEAM_USER } from 'config';
import moment from 'moment';

const { Option } = Select;
const regExpEmail = /^.+@\w{1,}.+\.\w{1,}/g;

const PartnerInformation = ({
  onBackToPartners,
  form,
  editedPartner,
  createNewPartner,
  updateCreatedPartner,
  isValuesChanged,
  nextButtonStyle,
  buttonDisabled
}) => {
  const { getFieldDecorator, getFieldsValue } = form;
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
    }
  };
  const onAddPartner = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        const newPartner = { ...values, startDate: moment(values.startDate).format('YYYY-MM-DD'), url: INVITE_TEAM_USER };
        createNewPartner(newPartner);
      }
    });
  };
  const onUpdatePartnerInfo = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        const updatedPartner = { ...values, startDate: moment(values.startDate).format('YYYY-MM-DD') };
        updateCreatedPartner(updatedPartner, 'package');
      }
    });
  };
  const isChangesSaved = () => {
    const currentValues = getFieldsValue();
    return isValuesChanged({
      ...currentValues,
      startDate: currentValues.startDate ? moment(currentValues.startDate).format('YYYY-MM-DD') : currentValues.startDate
    });
  };

  return (
    <Form
      {...formItemLayout}
      onSubmit={editedPartner ? onUpdatePartnerInfo : onAddPartner}
      hideRequiredMark
      labelAlign="left"
      className={style.companyForm}
    >
      <Form.Item label="Partner name" className={style.formItem}>
        {getFieldDecorator('name', {
          rules: [{ required: true, message: 'Missing partner name!' }],
          initialValue: editedPartner ? editedPartner.name : null
        })(<Input placeholder="name" type="text" />)}
      </Form.Item>
      <div className={style.selectMenuWrapper} id="status_id">
        <Form.Item label="Status" className={style.formItem}>
          <Form.Item style={{ display: 'inline-block', margin: '0' }}>
            {getFieldDecorator('status', {
              rules: [{ required: editedPartner ? false : true, message: 'Missing status!' }],
              initialValue: editedPartner ? editedPartner.status : null
            })(
              <Select
                style={{ width: '135px', marginRight: '10px' }}
                placeholder="Select status"
                getPopupContainer={() => document.getElementById('status_id')}
              >
                {STATUSES.map(status => (
                  <Option key={status.value} value={status.title}>
                    {status.title}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item style={{ display: 'inline-block', marginBottom: '0' }}>
            {getFieldDecorator('startDate', {
              rules: [{ required: true, message: 'Missing status date!' }],
              initialValue: editedPartner ? moment(editedPartner.startDate) : null
            })(<DatePicker format="YYYY/MM/DD" style={{ width: '90%', marginLeft: '12px' }} />)}
          </Form.Item>
        </Form.Item>
      </div>
      <div className={style.selectMenuWrapper} id="type_id">
        <Form.Item className={style.formItem} label="Partner type">
          {getFieldDecorator('type', {
            rules: [{ required: editedPartner ? false : true, message: 'Missing partner type!' }],
            initialValue: editedPartner ? editedPartner.type : undefined
          })(
            <Select placeholder="Select partner type" style={{ width: '100%' }} getPopupContainer={() => document.getElementById('type_id')}>
              {PARTNERS_TYPE.map(partnerType => (
                <Option key={partnerType} value={partnerType} className={style.typeSelect}>
                  {partnerType}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </div>
      <Form.Item className={style.formItem} label="Address">
        {getFieldDecorator('address', {
          rules: [{ required: editedPartner ? false : true, message: 'Missing address!' }],
          initialValue: editedPartner ? editedPartner.address : null
        })(<Input placeholder="address" type="text" />)}
      </Form.Item>
      <Form.Item className={style.formItem} label="Email">
        {getFieldDecorator('email', {
          rules: [
            {
              required: true,
              message: 'Missing email!'
            },
            {
              pattern: regExpEmail,
              message: 'The email address should contain @ symbol.Please check your email.'
            }
          ],
          initialValue: editedPartner ? editedPartner.email : null
        })(<Input placeholder="partner@email.com" type="text" />)}
      </Form.Item>

      <Form.Item>
        <div className={style.buttonsWrapper}>
          {isChangesSaved() ? (
            <Popconfirm
              title={
                editedPartner
                  ? "Changes aren't saved. Are you sure you want to go back?"
                  : "Company settings aren't saved. Are your sure you want to go back?"
              }
              onConfirm={onBackToPartners}
              okText="Yes"
              cancelText="No"
            >
              <span className={style.goBackButton}>Back</span>
            </Popconfirm>
          ) : (
            <span className={style.goBackButton} onClick={onBackToPartners}>
              Back
            </span>
          )}
          <Button type="primary" htmlType="submit" className={nextButtonStyle} disabled={buttonDisabled}>
            Next
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

const WrappedPartnerInformation = Form.create({ name: 'partner_info' })(PartnerInformation);

export default WrappedPartnerInformation;
