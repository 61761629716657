import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v2/api/partner`;

export const { types, actions, rootReducer } = createResource({
    name: 'partner',
    url,

    actions: {
        fetch: {
            method: 'GET',
            transformResponse: res => {
                return {
                    res,
                    body: res.body,
                }
            }
        },
        create: {
            method: 'POST'
        },
        update: {
            method: 'PATCH'
        },
        delete: {
            method: 'DELETE',
            reduce: (state, action) => {
                let updatedPartners = null;
                if (action.status === "resolved") {
                    updatedPartners = state.items[0].partners.filter(partner => {
                        return (partner.id !== action.context.id)
                    }
                    )
                }
                return updatedPartners ? { ...state, items: [{ ...state.items[0], partners: updatedPartners }] } : state
            },
        },

    },
}
);
