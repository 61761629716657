import React from 'react';
import { Spin } from 'antd';
import style from './style.module.less';
const Loader = () => {
    return (
        <div className={style.spinWrapper}>
            <Spin className={style.spinner} />
        </div>
    );
};

export default Loader;