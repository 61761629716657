import React from 'react';
import { Button, Icon } from 'antd';
import style from '../../style.module.less';


const SaveEditButton =({saveEditButton,saveSetting,isSettingSaving, editSetting,editedSetting, settingName, onCancelChanging })=> {
    return (
        <div className={style.saveEditButtonWrapper}>
        {editedSetting === settingName ?
            <>
                <Button  className = {saveEditButton} data-name={settingName} onClick={saveSetting} disabled={isSettingSaving} type='primary' loading={isSettingSaving} >
                {!isSettingSaving ? 'Save' : ''}
                </Button>
                <Button onClick = {onCancelChanging}>Cancel</Button>
             </>
               : (
             <Button data-name={settingName} className = {saveEditButton} onClick={editSetting}>
               <Icon type="edit" theme="filled" twoToneColor />
             </Button>
            )}
        
       </div>
    )

};
export default SaveEditButton;
