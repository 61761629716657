import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v2/api/user`;

export const { types, actions, rootReducer } = createResource({
    name: 'userSettings',
    url,
    actions: {
        update: {
            method: 'PATCH'
        },
    }
});