import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v2/api/admin/servicecredentials`;

export const { types, actions, rootReducer } = createResource({
  name: 'serviceCredentials',
  url,
  actions: {
    fetchServiceCredentials: {
      url: `${API_URL}v2/api/admin/servicecredentials`,
      method: 'GET'
    },
    patch: {
      method: 'PUT',
      url: `${API_URL}v2/api/admin/servicecredentials/update`,
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return state;
        }
        return { ...state };
      }
    },
    create: {
      method: 'POST',
      url: `${API_URL}v2/api/admin/servicecredentials/create`,
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return state;
        }
        return { ...state };
      }
    },
    remove: {
      method: 'DELETE',
      url: `${API_URL}v2/api/admin/servicecredentials/remove`,
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return state;
        }
        return { ...state };
      }
    }
  }
});
