import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v2/api/statistics/reviews/volume`;
export const { types, actions, rootReducer } = createResource({
  name: 'reviewsVolume',
  url,

  actions: {
    get: {
      method: 'GET',
      transformResponse: res => ({
        res,
        body: res.body.data
      })
    }
  }
});
