import { createResource } from 'redux-rest-resource';
import { API_URL } from '../../../config';

const url = `${API_URL}v2/api/competitor/pageData`;

export const { types, actions, rootReducer } = createResource({
    name: 'competitorsReport',
    url,
    actions: {
        fetch: {
            method: 'GET',
            transformResponse: res => ({
                res,
                body: res.body,
            })
        },
    }
});
