import React from 'react';
import { Button, message } from 'antd';
import style from './style.module.less';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';

// import copyButtonIcon from './../../../../assets/images/copyButtonIcon.png'


const ApiKey = ({ apiKey, nextButtonStyle }) => {

    const onCopyApiKey = () => {
        message.success('The API key has been copied to the clipboard.');
    }
    const currentPathname = window.location.pathname.split('/');
    currentPathname.splice(-1, 1);
    const pathname = currentPathname.join('/');

    return (
        <>
            <div className={style.apiKeyWrapper}>
                <span className={style.label}>Key </span>
                <p className={style.key}>{apiKey && apiKey}</p>
                <CopyToClipboard onCopy={text => onCopyApiKey()} text={apiKey && apiKey}>
                    <Button style={{ width: '33px', height: '33px', margin: '0' }} type='primary' icon="copy" />
                </CopyToClipboard>

            </div >
            <div className={style.buttonsWrapper}>
                <Link to={`${pathname}/package`} className={style.backButton}>
                    Back
                </Link>

                <Link to={`${pathname}/billing`}>
                    <Button type="primary" className={nextButtonStyle} >
                        Next
                </Button>
                </Link>
            </div>
        </>

    );
};

export default ApiKey;