export default (state = {}, action) => {
    switch (action.type) {
        case '@@resource/LANDING_PAGE_BRAND/FETCH':
            if (action.status === 'resolved') {
                return action.paginationLandingPageBrands;
            }
            return state;
        default:
            return state;
    }
};