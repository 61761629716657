import { createResource } from 'redux-rest-resource';
import { API_URL } from '../../config';

export const { types, actions, rootReducer } = createResource({
  name: 'postcomment',
  actions: {
    fetch: {
      method: 'POST',
      url: `${API_URL}v2/api/postcomment/get`,
      transformResponse: res => {
        return {
          res,
          body: res.body
        };
      },
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return state;
        }
        return { ...state, items: action.body };
      }
    }
  }
});
