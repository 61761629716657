import React from 'react';
import style from './style.module.less';
import RoleModel from '../../../../../containers/RoleModel/index';
import logo from 'assets/images/Localyser_Logo_Primary.png';
import { Button, Icon } from 'antd';
import { HELP_URL } from 'config';
import { withRouter } from 'react-router-dom';
import { getQueryParam } from 'helpers/helpers';
import { USER_ROLES } from 'constants/Role';

const PDF_EXPORT_MODE = () => !!window.REDUX_DATA;

const MainHeader = ({
  currentUser,
  ownerLikeUser,
  partnerLikeUser,
  adminLikeUser,
  ownerAsPartner,
  history,
  onToggleCollapsedNav,
  navCollapsed,
  location
}) => {
  const getQueryParams = () => getQueryParam(location.search);

  const reviewUrl = location.pathname;
  const queryParams = getQueryParams();
  const viewMode = queryParams.view;

  if (reviewUrl === '/reviews' && viewMode) {
    return null;
  }
  const allowedRoles = [USER_ROLES.Owner, USER_ROLES.Partner, USER_ROLES.MasterAdmin];
  return (
    <div className={style.headerLayoutWrapper}>
      <div className={style.headerLayout}>
        <div className={style.logoMenuWrapper} id="downloadPdfForBackend">
          <img src={logo} width="141px" height="40px" alt="locolyser_logo" />

          <Button onClick={onToggleCollapsedNav} className={style.menuIcon}>
            <Icon type={navCollapsed ? 'menu-unfold' : 'menu-fold'} />
          </Button>
        </div>
        {currentUser &&
          (!allowedRoles.includes(currentUser.role) && !ownerLikeUser && !partnerLikeUser && !ownerAsPartner && !adminLikeUser ? (
            <p className={style.currentCompany}>
              {currentUser.companyName} <span>({currentUser.role})</span>
            </p>
          ) : (
            <RoleModel currentUserRole={currentUser && currentUser.role} history={history} />
          ))}
        {!PDF_EXPORT_MODE() && <a href={HELP_URL} target="_blank" rel="noopener noreferrer">
          Help
        </a>}
      </div>
    </div>
  );
};

export default withRouter(MainHeader);
