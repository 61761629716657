import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v2/api/caseResolution`;

export const { types, actions, rootReducer } = createResource({
    name: 'caseResolution',
    url,

    actions: {
        fetch: {
            method: 'GET',
            transformResponse: res => {

                return ({
                    res,
                    body: res.body,
                })
            }
        },
        create: {
            method: 'POST'
        },
        updatePosition: {
            reduce: (state, action) => {
                return state
            },
            method: 'PATCH'
        },
        update: {
            method: 'PATCH',
        },
        delete: {
            method: 'DELETE'
        },
    }
});