import moment from 'moment/moment';

export const DATE_PICKER_TYPES = {
  dateFrom: 'date_from',
  dateTo: 'date_to',
  both: 'both'
};

export const DATE_PERIODS = {
  lastMonth: 'lastMonth',
  lastWeek: 'lastWeek',
  yesterday: 'yesterday',
  today: 'today',
  lastQuarter: 'lastQuarter',
  thisQuarter: 'thisQuarter',
  thisMonth: 'thisMonth',
  thisWeek: 'thisWeek'
};

export const DATE_RANGES = {
  day: 'day',
  week: 'week',
  month: 'month',
  quarter: 'quarter'
};

export const handleToday = () => moment().format('YYYY-MM-DD');

export const handleYesterday = () =>
  moment()
    .subtract(1, 'days')
    .format('YYYY-MM-DD');

export const handleCurrentRange = range => ({
  date_from: moment()
    .startOf(range)
    .format('YYYY-MM-DD'),
  date_to: moment().format('YYYY-MM-DD')
});

export const handlePreviousRange = range => ({
  date_from: moment()
    .subtract(1, `${range}s`)
    .startOf(range)
    .format('YYYY-MM-DD'),
  date_to: moment()
    .subtract(1, `${range}s`)
    .endOf(range)
    .format('YYYY-MM-DD')
});

const handlePeriod = period => {
  switch (period) {
    case DATE_PERIODS.today:
      return handleToday();
    case DATE_PERIODS.yesterday:
      return handleYesterday();
    case DATE_PERIODS.thisWeek:
      return handleCurrentRange(DATE_RANGES.week);
    case DATE_PERIODS.lastWeek:
      return handlePreviousRange(DATE_RANGES.week);
    case DATE_PERIODS.thisMonth:
      return handleCurrentRange(DATE_RANGES.month);
    case DATE_PERIODS.lastMonth:
      return handlePreviousRange(DATE_RANGES.month);
    case DATE_PERIODS.thisQuarter:
      return handleCurrentRange(DATE_RANGES.quarter);
    case DATE_PERIODS.lastQuarter:
      return handlePreviousRange(DATE_RANGES.quarter);
    default:
      return period;
  }
};

export const calculatePeriod = (dateFrom, dateTo) => {
  const periodsList =
    dateFrom === dateTo && Object.values(DATE_PERIODS).includes(dateFrom) && Object.values(DATE_PERIODS).includes(dateTo)
      ? [dateFrom]
      : [dateFrom, dateTo];

  const calculatedPeriod = periodsList.map(period => handlePeriod(period));

  if (calculatedPeriod.length === 1) {
    return calculatedPeriod[0];
  } else {
    return {
      date_from: calculatedPeriod[0],
      date_to: calculatedPeriod[1]
    };
  }
};
