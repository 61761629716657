import { createResource } from 'redux-rest-resource';
import { API_URL } from '../../config';

const url = `${API_URL}v2/api/locations/:id`;

export const { types, actions, rootReducer } = createResource({
  name: 'location',
  url,

  actions: {
    fetch: {
      method: 'GET',
      transformResponse: res => ({
        res,
        body: res.body.data,
        paginationLocations: {
          total_locations_count: res.body.total,
          page: res.body.page,
          per_page: res.body.per_page,
          total_pages: res.body.total_pages
        }
      })
    },
  }
});
