// import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import { UNDER_MAINTENANCE, isPRODUCTION } from './config';

import NextApp, { storePromise } from './NextApp';
import { Maintenance } from './components/Maintenance';
import { unregister } from './registerServiceWorker';
// Add this import:
import { AppContainer } from 'react-hot-loader';

// Wrap the rendering in a function:
const render = Component => {
  if (UNDER_MAINTENANCE && isPRODUCTION) {
    ReactDOM.render(<Maintenance />, document.getElementById('root'));
  } else {
    ReactDOM.render(
      // Wrap App inside AppContainer
      <AppContainer>
        <NextApp />
      </AppContainer>,
      document.getElementById('root')
    );
  }
};

// Do this once
// registerServiceWorker();
unregister();

storePromise.then(() =>
  // Render once
  render(NextApp)
);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./NextApp', () => {
    render(NextApp);
  });
}
