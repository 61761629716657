import React from 'react';
import { Switch, Tooltip, Icon } from 'antd';
import style from './style.module.less';

const settingsTitles = {
  reviewFilterSearch: 'Review Management',
  directResponse: 'Direct Response',
  feedback: 'Feedback',
  reviewCategorization: 'Review Categorization',
  caseResolution: 'Case Resolution',
  competitorBenchmarking: 'Competitor Benchmarking',
  autoresponse: 'Autoresponse',
  messaging: 'Messaging',
  insightsReport: 'Insights Report',
  сhatGPTAutoResponseGeneration: 'ChatGPT Auto-response Generation'
};
const settingsTooltips = {
  reviewFilterSearch:
    'This feature allows the user to view all reviews from the connected sources, filter & search reviews and respond to reviews at the review site through a link.',
  directResponse: 'This feature allows users to respond to reviews on selected sites directly from the dashboard.',
  feedback: 'This feature allows users to request reviews from customers using customer feedback landing page system',
  reviewCategorization: 'This features allow users to respond to reviews based on specific response text by a specific category.',
  caseResolution: 'This feature allows users to track the negative review case resolution status.',
  competitorBenchmarking: 'This features provides competitive comparison for up to three competitors per company location.',
  responseMenegment: 'This features provides to respond to reviews on the Review page.',
  autoresponse: 'This feature enables the brand to respond to Google rating-only reviews automatically.',
  insightsReport: 'A report that automatically categorizes reviews and provides product sentiment using natural language processing.',
  messaging: 'This feature allows the user to receive and reply to direct messages from social media accounts.',
  сhatgptautoresponse: 'This feature allows the user to  ChatGPT Auto-response Generation'
};

const PackageSettings = ({ settings, changePackageSettings, features }) => {
  const settingsNames = Object.keys(settings);

  const onChangeSettings = (value, event) => {
    const settingValue = value;
    const sattingName = event.currentTarget.dataset.name;
    changePackageSettings(sattingName, settingValue);
  };

  return (
    <div className={style.settingsWrapper}>
      {settingsNames.length > 0 &&
        settingsNames.map((settingName, index) => {
          return (
            <div key={settingName + index}>
              {settingName === 'reviewCategorization' ? (
                <>
                  <div className={style.labelSwitchWrapper} key="responseMenegment" id="label-switch">
                    <p className={style.settingLabel}>
                      Response Menegment
                      <Tooltip title={settingsTooltips.responseMenegment} getPopupContainer={() => document.getElementById('label-switch')}>
                        <Icon type="question-circle" className={style.tooltipIcon} />
                      </Tooltip>
                    </p>
                    <Switch
                      className={style.switchStyle}
                      checked={features.length > 0 && features.includes(settingsTitles[settingName])}
                      data-name="reviewCategorization"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={onChangeSettings}
                    />
                  </div>
                  <div className={style.labelSwitchWrapper} key={settingName + index} id="label-switch">
                    <p className={style.settingLabel}>
                      {settingsTitles[settingName]}
                      <Tooltip title={settingsTooltips[settingName]} getPopupContainer={() => document.getElementById('label-switch')}>
                        <Icon type="question-circle" className={style.tooltipIcon} />
                      </Tooltip>
                    </p>
                    <Switch
                      className={style.switchStyle}
                      checked={features.length > 0 && features.includes(settingsTitles[settingName])}
                      data-name={settingName}
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={onChangeSettings}
                    />
                  </div>
                </>
              ) : (
                <div className={style.labelSwitchWrapper} key={settingName + index} id="label-switch">
                  <p className={style.settingLabel}>
                    {settingsTitles[settingName] === 'Autoresponse' ? 'Auto-response' : settingsTitles[settingName]}
                    <Tooltip title={settingsTooltips[settingName]} getPopupContainer={() => document.getElementById('label-switch')}>
                      <Icon type="question-circle" className={style.tooltipIcon} />
                    </Tooltip>
                  </p>
                  <Switch
                    disabled={settingName === 'reviewFilterSearch'}
                    className={style.switchStyle}
                    checked={
                      features.length > 0 && settingName === 'reviewFilterSearch'
                        ? features.includes('Review Filter & Search')
                        : features.includes(settingsTitles[settingName])
                    }
                    data-name={settingName}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={onChangeSettings}
                  />
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default PackageSettings;
