export { default as checkCircle } from '@ant-design/icons/lib/outline/CheckCircleOutline';

export { default as start } from '@ant-design/icons/lib/fill/StarFill';
export { default as close } from '@ant-design/icons/lib/outline/CloseOutline';
export { default as redo } from '@ant-design/icons/lib/outline/RedoOutline';
export { default as questionСircleO } from '@ant-design/icons/lib/outline/QuestionCircleOutline';
export { default as closeCircle } from '@ant-design/icons/lib/fill/CloseCircleFill';
export { default as CloseCircleOutline } from '@ant-design/icons/lib/outline/CloseCircleOutline';
export { default as exception } from '@ant-design/icons/lib/outline/ExceptionOutline';
export { default as menuFold } from '@ant-design/icons/lib/outline/MenuFoldOutline';
export { default as menuUnfold } from '@ant-design/icons/lib/outline/MenuUnfoldOutline';
export { default as calendar } from '@ant-design/icons/lib/outline/CalendarOutline';

export { default as message } from '@ant-design/icons/lib/outline/MessageOutline';

export { default as hourglass } from '@ant-design/icons/lib/outline/HourglassOutline';

export { default as edit } from '@ant-design/icons/lib/fill/EditFill';
export { default as qrcode } from '@ant-design/icons/lib/outline/QrcodeOutline';
export { default as left } from '@ant-design/icons/lib/outline/LeftOutline';
export { default as right } from '@ant-design/icons/lib/outline/RightOutline';
export { default as down } from '@ant-design/icons/lib/outline/DownOutline';
export { default as up } from '@ant-design/icons/lib/outline/UpOutline';
export { default as user } from '@ant-design/icons/lib/outline/UserOutline';
export { default as mail } from '@ant-design/icons/lib/outline/MailOutline';
export { default as mobile } from '@ant-design/icons/lib/outline/MobileOutline';
export { default as check } from '@ant-design/icons/lib/outline/CheckOutline';
export { default as save } from '@ant-design/icons/lib/twotone/SaveTwoTone';
export { default as upload } from '@ant-design/icons/lib/outline/UploadOutline';
export { default as download } from '@ant-design/icons/lib/outline/DownloadOutline';
export { default as caretUp } from '@ant-design/icons/lib/fill/CaretUpFill';
export { default as caretDown } from '@ant-design/icons/lib/fill/CaretDownFill';
export { default as eye } from '@ant-design/icons/lib/outline/EyeOutline';
export { default as eyeInvisible } from '@ant-design/icons/lib/outline/EyeInvisibleOutline';
export { default as search } from '@ant-design/icons/lib/outline/SearchOutline';
export { default as exclamationCircle } from '@ant-design/icons/lib/fill/ExclamationCircleFill';
export { default as more } from '@ant-design/icons/lib/outline/MoreOutline';
export { default as CheckCircle } from '@ant-design/icons/lib/fill/CheckCircleFill';
export { default as link } from '@ant-design/icons/lib/outline/LinkOutline';
export { default as arrowLeft } from '@ant-design/icons/lib/outline/ArrowLeftOutline';
export { default as arrowRight } from '@ant-design/icons/lib/outline/ArrowRightOutline';
export { default as bgColors } from '@ant-design/icons/lib/outline/BgColorsOutline';
export { default as fontColors } from '@ant-design/icons/lib/outline/FontColorsOutline';
export { default as undo } from '@ant-design/icons/lib/outline/UndoOutline';
export { default as infoCircle } from '@ant-design/icons/lib/outline/InfoCircleOutline';
export { default as loading } from '@ant-design/icons/lib/outline/LoadingOutline';
export { default as caretDownOutline } from '@ant-design/icons/lib/outline/CaretDownOutline';
export { default as plus } from '@ant-design/icons/lib/outline/PlusOutline';
export { default as copy } from '@ant-design/icons/lib/outline/CopyOutline';
export { default as shareAlt } from '@ant-design/icons/lib/outline/ShareAltOutline';
export { default as instagram } from '@ant-design/icons/lib/outline/InstagramOutline';
export { default as stop } from '@ant-design/icons/lib/outline/StopOutline';
export { default as warning } from '@ant-design/icons/lib/fill/WarningFill';
