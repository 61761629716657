import React from 'react';
import { Button, message } from 'antd';
import style from './style.module.less';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Buttons from '../Butons';

const ApiKey = ({ apiKey, onBack, onNext }) => {
  const onCopyApiKey = text => {
    if (text) {
      message.success('The API key has been copied to the clipboard.');
    } else {
      message.error('No API to copy.');
    }
  };

  return (
    <>
      <div className={style.apiKeyWrapper}>
        <span className={style.label}>Key </span>
        <p className={style.key}>{apiKey && apiKey}</p>
        <CopyToClipboard onCopy={text => onCopyApiKey(text)} text={apiKey && apiKey}>
          <Button style={{ width: '33px', height: '33px', margin: '0' }} type="primary" icon="copy" />
        </CopyToClipboard>
      </div>
      <Buttons onBack={onBack} onNextToCompanies={onNext} nextButtonTitle="Back to companies" />
    </>
  );
};

export default ApiKey;
