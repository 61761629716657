export default (state = {}, action) => {
  switch (action.type) {
    case '@@resource/REVIEW/FETCH':
      if (action.status === 'resolved') {
        return action.pagination;
      }
      return state;

    default:
      return state;
  }
};
