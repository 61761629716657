import React from 'react';
import { COMPANY_TYPES } from 'config';
import { DEFAULT_COMPANY_SOURCES, ADITIONAL_COMPANY_SOURCES } from 'constants/Sources';
import { Checkbox, Popconfirm } from 'antd';
import style from './style.module.less';
import { sortResources } from 'helpers/helpers';
import CompetitorSources from '../CompetitorSources';

const additionalCompanySources = sortResources(ADITIONAL_COMPANY_SOURCES);
const defaultCompanySources = sortResources(DEFAULT_COMPANY_SOURCES);

const CompanySources = ({
  onSourceChange,
  onCompetitoeSourceChange,
  onBack,
  updateSources,
  defaultValue,
  editedCompany,
  onNext,
  checkedCompetitorsSources
}) => {
  const currentSources = defaultValue ? sortResources(defaultValue).join() : null;
  const companySources = editedCompany.sources ? sortResources(editedCompany.sources).join() : null;

  const currentCompetitorSources = checkedCompetitorsSources ? sortResources(checkedCompetitorsSources).join() : null;
  const companyCompetitorsSources = editedCompany.competitorsSources ? sortResources(editedCompany.competitorsSources).join() : null;

  const sources = editedCompany.type ? COMPANY_TYPES.find(company => company.company_type === editedCompany.type).sources : DEFAULT_COMPANY_SOURCES;
  return (
    <>
      <h2 className={style.competitorsSourcesTitle}>Company sources </h2>
      <Checkbox.Group
        disabled={editedCompany ? false : true}
        className={style.sourcesWrapper}
        onChange={onSourceChange}
        defaultValue={defaultValue ? defaultValue : sources}
      >
        <div className={style.companySources} key="defaultCompanySources">
          {defaultCompanySources.map(service => (
            <div className={style.sourcesCheckBox} key={service}>
              <Checkbox value={service}>{service}</Checkbox>
            </div>
          ))}
        </div>

        <div className={style.companyAdditionalSources} key="additionalCompanySources">
          {additionalCompanySources.map(service => (
            <div className={style.sourcesCheckBox} key={service}>
              <Checkbox value={service}>{service}</Checkbox>
            </div>
          ))}
        </div>
      </Checkbox.Group>
      {(!editedCompany || (editedCompany && editedCompany.features.includes('Competitor Benchmarking'))) && (
        <CompetitorSources
          editedCompany={editedCompany}
          onCompetitoeSourceChange={onCompetitoeSourceChange}
          selectedCompetitorsSources={checkedCompetitorsSources}
          sources={defaultValue}
          additionalCompanySources={additionalCompanySources}
        />
      )}

      <div className={style.buttonsWrapper}>
        {(currentSources !== companySources || currentCompetitorSources !== companyCompetitorsSources) && editedCompany ? (
          <Popconfirm title={"Changes aren't saved. Are you sure you want to go back?"} onConfirm={onBack} okText="Yes" cancelText="No">
            <span className={style.goBackButton}>Back</span>
          </Popconfirm>
        ) : (
          <span onClick={onBack} className={style.goBackButton}>
            Back
          </span>
        )}
        <button
          className={style.goNextButton}
          onClick={
            (currentSources !== companySources || currentCompetitorSources !== companyCompetitorsSources) && editedCompany ? updateSources : onNext
          }
        >
          Next
        </button>
      </div>
    </>
  );
};

export default CompanySources;
