import { createResource } from 'redux-rest-resource';

// ownerAsCompany, ownerAsUser, partnerAsCompany, partnerAsUser, adminAsUser

export const { types, actions, rootReducer } = createResource({
  name: 'userAsClient',
  actions: {
    updateRole: {
      isPure: true,
      reduce: (state, action) => {
        const changedItems = state.items[0]
          ? { ...state.items[0], [action.context.role]: { role_id: action.context.id, userName: action.context.userName } }
          : { [action.context.role]: { role_id: action.context.id, userName: action.context.userName } };
        return { ...state, items: [{ ...changedItems }] };
      }
    }
  }
});
