import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v2/api/admin/companies/:id`;

export const { types, actions, rootReducer } = createResource({
  name: 'company',
  url,

  actions: {
    fetch: {
      method: 'GET',
      transformResponse: res => {
        return {
          res,
          body: res.body.companies ? res.body.companies : res.body.data && res.body.data,
          paginationCompanies: {
            total_companies_count: res.body.count,
            page: res.body.page,
            per_page: res.body.per_page,
            total_pages: res.body.total_pages
          }
        };
      }
    },
    create: {
      method: 'POST'
    },
    update: {
      method: 'PATCH',
      transformResponse: res => {
        return {
          res,
          body: res.body
        };
      }
    },
    delete: {
      method: 'DELETE'
    },
    deleteMany: {
      method: 'DELETE',
      isArray: true,
      alias: 'delete'
    }
  }
});
