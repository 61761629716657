import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v2/api/admin/companies/:id/brands/:brand`;

export const { types, actions, rootReducer } = createResource({
  name: 'companyBrand',
  url,

  actions: {
    fetch: {
      method: 'GET',
      transformResponse: res => ({
        res,
        body: res.body.data,
        paginationCompanyBrands: {
          total_companyBrands_count: res.body.count,
          page: res.body.page,
          per_page: res.body.per_page,
          total_pages: res.body.total_pages
        }
      })
    },
    clear: {
      isPure: true,
      reduce: (state, action) => ({ ...state, item: null })
    },
    create: {
      method: 'POST'
    },
    update: {
      method: 'PATCH'
    },
    delete: {
      method: 'DELETE'
    },
    deleteMany: {
      method: 'DELETE',
      isArray: true,
      alias: 'delete'
    }
  }
});
