import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v2/api/admin/activecampaign`;

export const { types, actions, rootReducer } = createResource({
    name: 'activeCampaign',
    url,

    actions: {
        saveApi: {
            method: 'POST',
        },
        deleteApi: {
            method: 'DELETE',
        },
        saveBrands: {
          url: `${API_URL}v2/api/admin/activeCampaign/brands`,
          method: 'POST',
        },
    }
});
