/* eslint-disable no-undef */
import { Form, Select, Input, Popconfirm, Row, Col } from 'antd';
import React, { Component } from 'react';
import style from './style.module.less';
import { STATUSES, COMPANY_TYPES } from 'config';
import classnames from 'classnames';
import { getAllCountries } from 'countries-and-timezones';

const countriesData = getAllCountries();
const countries = [];
const timezones = [];

Object.values(countriesData).forEach(country => {
  countries.push(country.name);
  timezones.push(country.timezones[0]);
});

const { Option } = Select;

class CompanyInformation extends Component {
  state = {
    periodError: '',
    formUpdated: false
  };
  componentDidUpdate(prevProps) {
    if (this.props.created_at && this.props.created_at !== prevProps.created_at) {
      this.setState({
        periodError: ''
      });
    }
  }

  onUpdateCompanyInfo = e => {
    e.preventDefault();
    const { form, updateCompanyInformation, editedCompany, onNext } = this.props;
    form.validateFields((err, values) => {
      if (!err && form.isFieldsTouched()) {
        const updateCompany = {
          id: editedCompany.id,
          ...values
        };
        updateCompanyInformation(updateCompany);
        this.setState({
          formUpdated: true
        });
      } else {
        if (err) {
          if (!periodError) {
            this.recordPeriodError();
          }
        }
        if (!form.isFieldsTouched()) {
          onNext('sources');
        }
      }
    });
  };

  addCompany = e => {
    e.preventDefault();
    const { form, createCompany, onNext } = this.props;
    form.validateFields((err, values) => {
      if (!err && form.isFieldsTouched()) {
        const sources = values.type && COMPANY_TYPES.find(company => company.company_type === values.type).sources;
        const createBody = {
          ...values,
          sources
        };
        createCompany(createBody);
        this.setState({
          formUpdated: true
        });
      } else if (!form.isFieldsTouched()) {
        onNext('sources');
      }
    });
  };

  onformChange = e => {
    e.preventDefault();
    const { form, recordInformationChangeValues, editedCompany } = this.props;
    const currentValues = form.getFieldsValue();
    if (currentValues && form.isFieldsTouched()) {
      const updateCompany = {
        id: editedCompany.id,
        ...currentValues
      };
      recordInformationChangeValues(updateCompany);
    }
  };
  onTimeZoneChange = value => {
    const { resetFields } = this.props.form;
    !value && resetFields('timeZone');
  };

  render() {
    const { getFieldDecorator, isFieldsTouched } = this.props.form;
    const { editedCompany, onBack, visible } = this.props;
    const { periodError, formUpdated } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };
    let otherCompanyType = {
      company_type: 'Other',
      sources: ['Google', 'Facebook', 'Foursquare']
    };
    const sortedCompaniesTypes = COMPANY_TYPES.sort((a, b) => a.company_type.localeCompare(b.company_type)).filter(
      company => company.company_type !== 'Other'
    );

    sortedCompaniesTypes.push(otherCompanyType);

    return (
      <>
        <Form
          {...formItemLayout}
          onSubmit={editedCompany ? this.onUpdateCompanyInfo : this.addCompany}
          onChange={this.onformChange}
          labelAlign="left"
          className={classnames(visible ? style.companyForm : style.hideForm)}
        >
          <Form.Item label="Company name" className={style.formItem}>
            {getFieldDecorator('company_name', {
              rules: [{ required: true, message: 'Missing company name!' }],
              initialValue: editedCompany ? editedCompany.company_name : null
            })(<Input type="text" />)}
          </Form.Item>
          <div className={style.selectMenuWrapper} id="status_id">
            <Form.Item label="Status" className={style.formItem}>
              <Row gutter={8}>
                <Col span={10}>
                  {getFieldDecorator('status', {
                    rules: [{ required: editedCompany ? false : true, message: 'Missing status!' }],
                    initialValue: editedCompany ? editedCompany.status : 'Inactive'
                  })(
                    <Select placeholder="Status" getPopupContainer={() => document.getElementById('status_id')}>
                      {STATUSES.map(status => (
                        <Option key={status.value} value={status.title}>
                          {status.title}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Col>
              </Row>
              {periodError && <p className={style.created_atError}>{periodError}</p>}
            </Form.Item>
          </div>
          <div className={style.selectMenuWrapper} id="type_id">
            <Form.Item className={style.formItem} label="Company type">
              <Row gutter={8}>
                <Col span={16}>
                  {getFieldDecorator('type', {
                    rules: [{ required: editedCompany ? false : true, message: 'Missing company type!' }],
                    initialValue: editedCompany ? editedCompany.type : ''
                  })(
                    <Select placeholder="Company" getPopupContainer={() => document.getElementById('type_id')}>
                      <Option className={style.defaultValue} value={null}>
                        ...
                      </Option>
                      {sortedCompaniesTypes.map(company => (
                        <Option key={company.company_type} value={company.company_type} className={style.typeSelect}>
                          {company.company_type}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Col>
              </Row>
            </Form.Item>
          </div>
          <Form.Item className={style.formItem} label="Address 1">
            {getFieldDecorator('address1', {
              rules: [{ required: editedCompany ? false : true, message: 'Missing address!' }],
              initialValue: editedCompany ? editedCompany.address1 : null
            })(<Input type="text" />)}
          </Form.Item>
          <Form.Item className={style.formItem} label="Address 2">
            {getFieldDecorator('address2', {
              initialValue: editedCompany ? editedCompany.address2 : null
            })(<Input type="text" />)}
          </Form.Item>
          <Form.Item className={style.formItem} label="City">
            {getFieldDecorator('city', {
              rules: [{ required: editedCompany ? false : true, message: 'Missing city!' }],
              initialValue: editedCompany ? editedCompany.city : null
            })(<Input type="text" />)}
          </Form.Item>
          <Form.Item className={style.formItem} label="State">
            {getFieldDecorator('state', {
              rules: [{ required: editedCompany ? false : true, message: 'Missing state!' }],
              initialValue: editedCompany ? editedCompany.state : null
            })(<Input type="text" />)}
          </Form.Item>
          <div className={style.selectMenuWrapper} id="country">
            <Form.Item className={style.formItem} label="Country">
              {getFieldDecorator('country', {
                rules: [{ required: editedCompany ? false : true, message: 'Missing country!' }],
                initialValue: editedCompany ? editedCompany.country : null
              })(
                <Select
                  placeholder="Country"
                  showSearch
                  allowClear
                  filterOption={(input, option) => {
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                  optionFilterProp="children"
                  getPopupContainer={() => document.getElementById('country')}
                >
                  {Object.values(countries).map(country => (
                    <Option key={country} value={country}>
                      {country}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            {editedCompany && (
              <div className={style.selectMenuWrapper} id="timeZone">
                <Form.Item className={style.formItem} label="Time zone">
                  {getFieldDecorator('timeZone', {
                    rules: [{ required: true, message: 'Missing time zone!' }],
                    initialValue: editedCompany.timeZone
                  })(
                    <Select
                      placeholder="Time zone"
                      showSearch
                      allowClear
                      onChange={value => this.onTimeZoneChange(value)}
                      filterOption={(input, option) => {
                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                      }}
                      optionFilterProp="children"
                      getPopupContainer={() => document.getElementById('timeZone')}
                    >
                      {countries &&
                        timezones.sort().map(timezone => (
                          <Option key={timezone} value={timezone}>
                            {timezone}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </div>
            )}
          </div>
          <Form.Item className={style.formItem} label="Zip code">
            {getFieldDecorator('zip', {
              initialValue: editedCompany ? +editedCompany.zip : null
            })(<Input type="number" />)}
          </Form.Item>
          <Form.Item>
            <div className={style.buttonsWrapper}>
              {isFieldsTouched() && !formUpdated ? (
                <Popconfirm
                  title={
                    editedCompany
                      ? "Changes aren't saved. Are you sure you want to go back?"
                      : "Company settings aren't saved. Are your sure you want to go back?"
                  }
                  onConfirm={onBack}
                  okText="Yes"
                  cancelText="No"
                >
                  <span className={style.goBackButton}>Back</span>
                </Popconfirm>
              ) : (
                <span className={style.goBackButton} onClick={onBack}>
                  Back
                </span>
              )}
              <button className={style.nextButton} htmltype="submit">
                Next
              </button>
            </div>
          </Form.Item>
        </Form>
      </>
    );
  }
}

const WrappedCompanyInformation = Form.create({ name: 'team_settings' })(CompanyInformation);

export default WrappedCompanyInformation;
