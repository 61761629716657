export default (state = {}, action) => {
    switch (action.type) {
        case '@@resource/TEAM_USER/FETCH':
            if (action.status === 'resolved') {
                return action.paginationTeamUsers;
            }
            return state;
        default:
            return state;
    }
};