import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { actions as currentUserActions } from 'appRedux/resources/currentUser/currentUserResources';
import { actions as companiesActions } from 'appRedux/resources/companies/companiesResources';
import { connect } from 'react-redux';
import { clearLocalStoragFromUserRoles } from 'helpers/helpers';
import { USER_ROLES } from 'constants/Role';
import { Breadcrumb } from 'antd';
import style from './style.module.less';

const RoleModel = ({ history, currentUser, fetchCurrentUsers, fetchCompanys }) => {
  const [userAsClient, setUserAsClient] = useState(null);
  const [userAsUser, setUserAsUser] = useState(null);
  const [ownerAsPartner, setOwnerAsPartner] = useState(null);
  const [adminAsUser, setAdminAsUser] = useState(null);

  const returnToOwner = async () => {
    setUserAsClient(null);
    setUserAsUser(null);
    setOwnerAsPartner(null);
    const ownerLikePartner = JSON.parse(localStorage.getItem('ownerAsPartner') || '{}').userName;
    const returnPath = ownerLikePartner ? '/admin_panel/partners' : '/admin_panel/companies';
    clearLocalStoragFromUserRoles();
    //update current users settings
    await fetchCurrentUsers();

    history.push({
      pathname: returnPath
    });
  };
  const returnToOwnerAsPartner = () => {
    setUserAsClient(null);
    setUserAsUser(null);

    localStorage.removeItem('ownerAsCompany');
    localStorage.removeItem('ownerAsUser');

    localStorage.removeItem('partnerAsCompany');
    localStorage.removeItem('partnerAsUser');
    history.push({
      pathname: '/admin_panel/companies'
    });
  };
  const returnToCompany = () => {
    const { role } = currentUser;

    setUserAsUser(null);
    localStorage.removeItem('partnerAsUser');
    localStorage.removeItem('ownerAsUser');

    if (role !== USER_ROLES.Partner && role !== USER_ROLES.Owner) {
      fetchCurrentUsers();
    }
    fetchCompanys(
      {},
      {
        query: { direction: 'desc' }
      }
    );
    history.push({
      pathname: '/settings/team_access'
    });
  };
  const returnToAdmin = async () => {
    setAdminAsUser(null);
    clearLocalStoragFromUserRoles();
    await fetchCurrentUsers();
    history.push({
      pathname: '/settings/team_access'
    });
  }

  const partnerLikeCompany = JSON.parse(localStorage.getItem('partnerAsCompany') || '{}').userName;

  const ownerLikeCompany = JSON.parse(localStorage.getItem('ownerAsCompany') || '{}').userName;

  const ownerLikeUser = JSON.parse(localStorage.getItem('ownerAsUser') || '{}').userName;
  const partnerLikeUser = JSON.parse(localStorage.getItem('partnerAsUser') || '{}').userName;

  const ownerLikePartner = JSON.parse(localStorage.getItem('ownerAsPartner') || '{}').userName;

  const adminLikeUser = JSON.parse(localStorage.getItem('adminAsUser') || '{}').userName
  
  useEffect(() => {
    const userAsCompany = ownerLikeCompany ? ownerLikeCompany : partnerLikeCompany ? partnerLikeCompany : null;
    const ownerAsPartnerName = ownerLikePartner ? `${USER_ROLES.Partner} - ${ownerLikePartner}` : null;
    const userAsClientName = ownerLikeUser ? ownerLikeUser : partnerLikeUser ? partnerLikeUser : null;
    const adminAsUser = adminLikeUser ? adminLikeUser : null;

    if (userAsCompany !== userAsClient) {
      userAsCompany && setUserAsClient(userAsCompany);
    }
    if (userAsClientName !== userAsUser) {
      userAsClientName && setUserAsUser(userAsClientName);
    }
    if (ownerAsPartnerName && ownerAsPartnerName !== ownerAsPartner) {
      setOwnerAsPartner(ownerAsPartnerName);
    }
    if (adminAsUser) {
      setAdminAsUser(adminAsUser)
    }
  }, [userAsClient, userAsUser, partnerLikeCompany, ownerLikeCompany, ownerLikeUser, partnerLikeUser, ownerLikePartner, ownerAsPartner, adminAsUser, adminLikeUser]);

  const { role } = currentUser;
  const currentUserRole = ownerLikePartner || userAsUser ? USER_ROLES.Owner : adminLikeUser ? USER_ROLES.MasterAdmin : role;
  return (
    <Breadcrumb className={style.breadCrumbs} separator=">">
      {!adminAsUser && <Breadcrumb.Item onClick={() => returnToOwner()}>{currentUserRole}</Breadcrumb.Item>}
      {adminAsUser && <Breadcrumb.Item onClick={() => returnToAdmin()}> {currentUserRole}</Breadcrumb.Item>}
      {adminAsUser && <Breadcrumb.Item> {adminAsUser} ({role})</Breadcrumb.Item>}
      {ownerAsPartner && <Breadcrumb.Item onClick={() => returnToOwnerAsPartner()}> {ownerAsPartner}</Breadcrumb.Item>}
      {userAsClient && <Breadcrumb.Item onClick={() => returnToCompany()}> {userAsClient}</Breadcrumb.Item>}
      {userAsUser && (
        <Breadcrumb.Item>
          {userAsUser} ({role})
        </Breadcrumb.Item>
      )}
    </Breadcrumb>
  );
};
const MSTP = state => {
  return {
    userAsClient: state.team_users.userAsClient.items[0],
    currentUser: state.team_users.currentUser.items[0]
  };
};

export default connect(
  MSTP,
  dispatch => ({
    ...bindActionCreators(currentUserActions, dispatch),
    ...bindActionCreators(companiesActions, dispatch)
  })
)(RoleModel);
