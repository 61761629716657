import React, { Component } from 'react';

import { DatePicker } from 'antd';
import moment from 'moment';

import style from './dateRange.module.less';

import { DATE_PERIODS, DATE_PICKER_TYPES } from '../../../helpers/datePeriods';

class DateRange extends Component {
  state = {
    isFromStartOpened: false,
    isFormEndOpened: false
  };

  disabledStartDate = startValue => {
    const { endValue } = this.props;

    return startValue?.valueOf() > endValue?.valueOf() || moment().valueOf() < startValue?.valueOf();
  };

  disabledEndDate = endValue => {
    const { startValue } = this.props;

    return endValue?.valueOf() <= startValue?.valueOf() || moment().valueOf() < endValue?.valueOf();
  };

  formStartOpened = isFromOpened => {
    this.setState({
      isFromStartOpened: isFromOpened
    });
  };

  onStartChange = value => {
    this.props.onStartChange(value);
    this.setState({
      isFromStartOpened: false
    });
  };

  formEndOpened = isFromOpened => {
    this.setState({
      isFormEndOpened: isFromOpened
    });
  };

  onEndChange = value => {
    this.props.onEndChange(value);
    this.setState({
      isFormEndOpened: false
    });
  };

  onDateRange = (date_from, date_to) => {
    this.props.updateDateRange(date_from, date_to);
    this.setState({
      isFromStartOpened: false,
      isFormEndOpened: false
    });
  };
  onChoseToday = () => {
    this.onDateRange(moment());
    this.props.handleDatePeriod && this.props.handleDatePeriod(DATE_PICKER_TYPES.dateFrom, DATE_PERIODS.today);
  };

  onChoseYesterday = () => {
    this.onDateRange(moment().subtract(1, 'days'));
    this.props.handleDatePeriod && this.props.handleDatePeriod(DATE_PICKER_TYPES.dateFrom, DATE_PERIODS.yesterday);
  };

  onChoseThisWeek = () => {
    this.onDateRange(moment().startOf('week'), moment());
    this.props.handleDatePeriod && this.props.handleDatePeriod(DATE_PICKER_TYPES.both, DATE_PERIODS.thisWeek);
  };

  onChoseThisMonth = () => {
    this.onDateRange(moment().startOf('month'), moment());
    this.props.handleDatePeriod && this.props.handleDatePeriod(DATE_PICKER_TYPES.both, DATE_PERIODS.thisMonth);
  };

  onChoseThisQuarter = () => {
    this.onDateRange(moment().startOf('quarter'), moment());
    this.props.handleDatePeriod && this.props.handleDatePeriod(DATE_PICKER_TYPES.both, DATE_PERIODS.thisQuarter);
  };

  onChoseLastWeek = () => {
    const date_from = moment()
      .startOf('week')
      .subtract(1, 'weeks');
    const date_to = moment()
      .startOf('week')
      .subtract(1, 'days');

    this.onDateRange(date_from, date_to);
    this.props.handleDatePeriod && this.props.handleDatePeriod(DATE_PICKER_TYPES.both, DATE_PERIODS.lastWeek);
  };

  onChoseLastMonth = () => {
    const date_from = moment()
      .subtract(1, 'months')
      .startOf('month');
    const date_to = moment()
      .subtract(1, 'months')
      .endOf('month');

    this.onDateRange(date_from, date_to);
    this.props.handleDatePeriod && this.props.handleDatePeriod(DATE_PICKER_TYPES.both, DATE_PERIODS.lastMonth);
  };

  onChoseLastQuarter = () => {
    const date_from = moment()
      .subtract(1, 'quarters')
      .startOf('quarter');
    const date_to = moment()
      .subtract(1, 'quarters')
      .endOf('quarter');

    this.onDateRange(date_from, date_to);
    this.props.handleDatePeriod && this.props.handleDatePeriod(DATE_PICKER_TYPES.both, DATE_PERIODS.lastQuarter);
  };

  render() {
    const { startValue, endValue, classname, todayYesterdayStyle, extraFooter = true } = this.props;
    const { isFromStartOpened, isFormEndOpened } = this.state;
    return (
      <div className={style.datePickerWrapper}>
        <div className={style.selectMenuWrapper} id="date_from">
          <DatePicker
            className={classname ? classname : style.datePicker}
            disabledDate={this.disabledStartDate}
            format="YYYY-MM-DD"
            showToday={false}
            open={isFromStartOpened}
            value={startValue}
            placeholder="From"
            onOpenChange={isFromOpened => this.formStartOpened(isFromOpened)}
            onChange={this.onStartChange}
            getCalendarContainer={() => document.getElementById('date_from')}
            renderExtraFooter={() =>
              extraFooter && (
                <div>
                  <div className={todayYesterdayStyle ? todayYesterdayStyle : style.extraFooterWrapper}>
                    <p className={style.extraFooterItem} onClick={this.onChoseToday}>
                      Today
                    </p>
                    <p className={style.extraFooterItem} onClick={this.onChoseYesterday}>
                      Yesterday
                    </p>
                  </div>
                  <div className={style.extraFooterWrapper}>
                    <p className={style.extraFooterItem} onClick={this.onChoseThisWeek}>
                      This week
                    </p>
                    <p className={style.extraFooterItem} onClick={this.onChoseLastWeek}>
                      Last week
                    </p>
                  </div>
                  <div className={style.extraFooterWrapper}>
                    <p className={style.extraFooterItem} onClick={this.onChoseThisMonth}>
                      This month
                    </p>
                    <p className={style.extraFooterItem} onClick={this.onChoseLastMonth}>
                      Last month
                    </p>
                  </div>
                  <div className={style.extraFooterWrapper}>
                    <p className={style.extraFooterItem} onClick={this.onChoseThisQuarter}>
                      This quarter
                    </p>
                    <p className={style.extraFooterItem} onClick={this.onChoseLastQuarter}>
                      Last quarter
                    </p>
                  </div>
                </div>
              )
            }
          />
        </div>
        <div className={style.selectMenuWrapper} id="date_to">
          <DatePicker
            className={classname ? classname : style.datePicker}
            disabledDate={this.disabledEndDate}
            format="YYYY-MM-DD"
            value={endValue}
            placeholder="To"
            onChange={this.onEndChange}
            open={isFormEndOpened}
            onOpenChange={isFromOpened => this.formEndOpened(isFromOpened)}
            showToday={false}
            getCalendarContainer={() => document.getElementById('date_to')}
            renderExtraFooter={() =>
              extraFooter && (
                <div>
                  <div className={style.extraFooterWrapper}>
                    <p className={style.extraFooterItem} onClick={this.onChoseThisWeek}>
                      This week
                    </p>
                    <p className={style.extraFooterItem} onClick={this.onChoseLastWeek}>
                      Last week
                    </p>
                  </div>
                  <div className={style.extraFooterWrapper}>
                    <p className={style.extraFooterItem} onClick={this.onChoseThisMonth}>
                      This month
                    </p>
                    <p className={style.extraFooterItem} onClick={this.onChoseLastMonth}>
                      Last month
                    </p>
                  </div>
                  <div className={style.extraFooterWrapper}>
                    <p className={style.extraFooterItem} onClick={this.onChoseThisQuarter}>
                      This quarter
                    </p>
                    <p className={style.extraFooterItem} onClick={this.onChoseLastQuarter}>
                      Last quarter
                    </p>
                  </div>
                </div>
              )
            }
          />
        </div>
      </div>
    );
  }
}

DateRange.defaultProps = {
  handleDatePeriod: null
};

export default DateRange;
