import { createResource } from 'redux-rest-resource';
import { API_URL } from '../../config';

export const { types, actions, rootReducer } = createResource({
  name: 'socialpost',
  actions: {
    fetch: {
      method: 'POST',
      url: `${API_URL}v2/api/socialpost/get`,
      transformResponse: res => {
        return {
          res,
          body: res.body
        };
      },
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return {
            ...state,
            isFetching: true
          };
        }
        if (action.context.skip !== 0) {
          return {
            ...state,
            isFetching: false,
            items: [...state.items, ...action.body]
          };
        } else {
          return {
            ...state,
            items: [...action.body],
            isFetching: false
          };
        }
      }
    },

    get: {
      method: 'GET',
      url: `${API_URL}v2/api/socialpost/get/:postId`,
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return state;
        } else {
          const post = action.body;
          const foundIndex = state.items.findIndex(el => el.postId === post.postId);

          let updatedItems;

          if (foundIndex !== -1) {
            updatedItems = state.items.map(el => (el.postId === post.postId ? post : el));
          } else {
            updatedItems = [post, ...state.items];
          }

          return { ...state, items: updatedItems };
        }
      }
    },

    update: {
      method: 'PATCH',
      url: `${API_URL}v2/api/socialpost/update/:id`,
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return state;
        } else {
          const { post } = action.body;

          const posts = state.items.filter(item => item.id !== post.id);

          return { ...state, items: posts };
        }
      }
    },

    getUnreadTotalCount: {
      method: 'GET',
      url: `${API_URL}v2/api/socialpost/unreadTotalCount`,
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return state;
        }
        return { ...state, unreadTotalCount: action.body.unreadTotalCount };
      }
    },

    updateUnreadCount: {
      method: 'PATCH',
      url: `${API_URL}v2/api/socialpost/updateCount`,
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return state;
        } else {
          const { postId, unreadCount } = action.body[0];

          const updatedItems = state.items.map(el => (el.postId === postId ? { ...el, unreadCount } : el));

          return { ...state, items: updatedItems };
        }
      }
    }
  }
});
