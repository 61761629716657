import React, { useState, useEffect } from 'react';
import { API_URL } from 'config';
import style from './sidebar.module.less';

const AppVersion = () => {
  const [versionApi, setVersionApi] = useState('');
  const [versionUi, setVersionUi] = useState('');

  useEffect(() => {
    async function fetchVersions() {
      try {
        const responseVersionApi = await fetch(`${API_URL}version`);
        const dataApi = await responseVersionApi.json();
        setVersionApi(`${dataApi.version}:${dataApi.build}`);

        const responseVersionUi = await fetch('version.json');
        const dataUi = await responseVersionUi.json();
        setVersionUi(`${dataUi.version}:${dataUi.build}`);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchVersions();
  }, []);

  return (
    <div className={style.appVersionWrapper}>
      <div>App: {versionUi}</div>
      <div>API: {versionApi}</div>
    </div>
  );
};

export default AppVersion;
