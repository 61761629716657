import { createResource } from 'redux-rest-resource';
import { API_URL } from '../../config';

export const { types, actions, rootReducer } = createResource({
  name: 'response',
  actions: {
    fetchResponse: {
      method: 'POST',
      url: `${API_URL}v2/api/response/get`,
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return state;
        }
        return { ...state, items: action.body };
      }
    },
    create: {
      method: 'POST',
      url: `${API_URL}v2/api/response/create`,
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return state;
        }
        return { ...state, items: action.body };
      }
    },
    update: {
      method: 'PATCH',
      url: `${API_URL}v2/api/response/update/:id`,
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return state;
        }
        return { ...state, items: action.body };
      }
    },
    delete: {
      method: 'DELETE',
      url: `${API_URL}v2/api/response/delete/:id`
    }
  }
});
