import { createResource } from 'redux-rest-resource';
import { API_URL } from '../../config';

export const { types, actions, rootReducer } = createResource({
  name: 'tags',
  actions: {
    fetch: {
      method: 'POST',
      url: `${API_URL}v2/api/tags/get`,
      transformResponse: res => {
        return {
          res,
          body: res.body
        };
      }
    },
    create: {
      method: 'POST',
      url: `${API_URL}v2/api/tags/create`,
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return state;
        }
        const tags = [...state.items, action.body];
        return { items: tags };
      }
    },
    updateTags: {
      method: 'PATCH',
      url: `${API_URL}v2/api/tags/update/:id`,
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return state;
        }
        const tags = [...state.items, action.body];
        return { items: tags };
      }
    },
    deleteTags: {
      method: 'DELETE',
      url: `${API_URL}v2/api/tags/delete/:id`
    }
  }
});
