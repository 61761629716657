import { createResource } from 'redux-rest-resource';
import { API_URL } from 'config';

const url = `${API_URL}v2/api/reviews/:id`;

export const { types, actions, rootReducer } = createResource({
  name: 'review',
  url,

  actions: {
    fetch: {
      method: 'GET',
      transformResponse: res => ({
        res,
        body: res.body.reviews,
        pagination: {
          total_reviews_count: res.body.total_reviews_count,
          page: res.body.page,
          per_page: res.body.per_page,
          total_pages: res.body.total_pages
        }
      })
    },
    deleteFeedback: {
      reduce: (state, action) => {
        const updatedItems = state.items.filter(item => item.id !== action.context.id);
        if (action.status !== 'resolved') {
          return state;
        }
        return { ...state, items: updatedItems };
      },
      method: 'DELETE'
    },
    caseResolution: {
      reduce: (state, action) => {
        const updatedItems = state.items.map(item =>
          item.id === action.context.id ? { ...item, caseResolution: action.context.case_resolution } : item
        );
        if (action.status !== 'resolved') {
          return state;
        }
        return { ...state, items: updatedItems };
      },
      method: 'PATCH'
    },
    updateCategoty: {
      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return state;
        }
        const updatedItems = state.items.map(item =>
          item.id === action.context.id ? { ...item, reviewCategoryId: action.context.review_categories } : item
        );
        return { ...state, items: updatedItems };
      },
      method: 'PATCH'
    },
    update: {
      method: 'PATCH'
    }
  }
});
