export const EVENTS = {
  count: 'count',
  message: 'message',
  post: 'post',
  comment: 'comment'
};

export const CHANNELS = {
  notifications: 'notifications',
  totalCount: 'total-count'
};
